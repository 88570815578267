import React, {useEffect} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { getUserById , loginUser, updatePassword } from '../../../API/Api';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";


const UserProfile = () => { 
  const token = localStorage.getItem('accessToken')
  const decodedToken = jwtDecode(token)
  
  const passwordChangeSchema = z.object({
    currentPassword: z.string().min(2), newPassword: z.string().min(2), confirmPassword: z.string().min(2) // confirmPassword: z.string().min(2, { message: "Confirm password must be at least 8 characters long" }),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "New password and confirm password must match",
    path: ["confirmPassword"],
  })  
  // })
  // // .refine((data) => /[A-Z]/.test(data.newPassword), {
  // //   message: "New password must contain at least one uppercase letter",
  // //   path: ["newPassword"],
  // // }) 
 
  const { register, handleSubmit, setError, reset, formState: {errors, isSubmitting} } = useForm({
    defaultValues: {currentPassword: '', newPassword: '', confirmPassword: '' },
    resolver: zodResolver(passwordChangeSchema)
  }); 


  const onSubmit = async (passwordData) => {
    console.log('Password update:', passwordData);

    try {
      const newestEmail = (await getUserById(decodedToken._id, token)).data.email 

      const currentPasswordMatches = (await loginUser({
        email: newestEmail,
        password: passwordData.currentPassword
      })).data.status

      if (currentPasswordMatches) {
        await updatePassword({ password: passwordData.confirmPassword}, token)
        reset();
        toast.success('Password successfully updated!')
      } else {
        toast.error('Current Password incorrect')
      }

    } catch(e) {
      toast.error('Something went wrong...')
    }
    //   setError("currentPassword", {message: "Please ensure correct password"})
    //   setError("newPassword", {message: "Please ensure new password matches"})
    //   setError("confirmPassword", {message: "Please ensure new password matches"})
  };

  const inputClass = "w-11/12 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500";
  const labelClass = "block text-sm font-medium text-gray-700";
  const buttonClass = "bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-md";

  return (
    <>
        <div className="w-11/12">
          <div>
            <h2 className="text-xl font-semibold mb-4">Change Password</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-4">
                <label className={labelClass}>Current Password</label>
                <input type="password" {...register('currentPassword')} className={inputClass} />
              </div>
              {errors.currentPassword && ( <div className="text-red-500">{errors.currentPassword.message}</div> )}

              <div className="mb-4">
                <label className={labelClass}>New Password</label>
                <input type="password" {...register('newPassword')} className={inputClass} />
              </div>

              <div className="mb-4">
                <label className={labelClass}>Confirm Password</label>
                <input type="password" {...register('confirmPassword')} className={inputClass} />
              </div>
              {errors.confirmPassword && ( <div className="text-red-500">{errors.confirmPassword.message}</div> )}
              
              <div className="mt-6">
                <button type="submit" disabled={isSubmitting} className={`bg-green-500 text-white font-semibold py-2 px-4 rounded-md transition-all duration-300 ${isSubmitting ? 'opacity-50 cursor-not-allowed': 'hover:bg-green-600' }`}>
                   {isSubmitting ? 'Updating...' : 'Update Password'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
  );
};

export default UserProfile;
