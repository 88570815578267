import React, { useState, useEffect } from 'react';
import { uploadFile, createkyc, updatekyc, getkycCustomerPastRisk, updateKycRescreeningDate, createKycComment, getkycRiskAssessment, createKycNestedSanctionComment, sendEmailNotification, getkyc, getkycAll, updatekycStatus, sanctionsHits, adverseNewsHits, createkycRiskAssessment, updatekycRiskAssessment, getAllUsers} from "../../../API/Api" 
import { Card, Row } from '../../shared/utils';
import FormStatusEnum from '../../../data/FormStatusEnum';
import QuestionCard from './QuestionCard'; 
import { useLocation } from 'react-router-dom';
import { DateTime } from "luxon"
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import SanctionsioScreeningCard from './SanctionsioScreeningCard';
import { Tooltip } from '@mui/material';
import SubmissionCard from './SubmissionCard';
import { jwtDecode } from "jwt-decode";

let isKycDataExpired;
let expiredPartyId = [];
// kycData is being retrieved twice, can remove the useeffect fetch here since in parent __KycAmlForm fetch alr
// const IndividualRiskAssessment = ({userId}) => {
const _RiskAssessment = ({userId, userType, currentUrl, token}) => {
 
  const [allFormData, setAllFormData] = useState([])
  const [formData, setFormData] = useState({
    pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJursdictionHits:[], adverseNewsHits:[], 
    riskLevel: 'Standard', sourceOfWealthDocumentURL: '', otherDocumentsURL: '', managementSupportDocumentURL: ''
  }); 
  const [kycData, setKycData] = useState({}) //nested within 'details' // identificationDocumentLink: '', identificationExpiryDate : ''
  const [kycDataIdentity, setKycDataIdentity] = useState({identificationDocumentLink: '', identificationExpiryDate : ''})

  const [loaded, setLoaded] = useState(true);
  const [fetchDataAgain, setFetchDataAgain] = useState(false); 
  const [assessmentRunning, setAssessmentRunning] = useState(false);
  const [allManagers, setAllManagers] = useState([])
  const userRole = localStorage.getItem('role')
  let individualOrEntityConnectedPartyToCheck
  let lastSubmittedEmail

  const decodedToken =  jwtDecode(token) 

  let disabled = false
  if (decodedToken.role == 'viewer') { disabled = true }

  useEffect(() => {
    const fetchData = async () => { 

      if (fetchDataAgain === true) {
        setAssessmentRunning(true);
      }

      let allManagers = (await getAllUsers(token)).data
      allManagers = allManagers.filter(users => users.role !== 'executive' && users.role !== 'viewer');
      const currentKycData = await getkyc(userId, token).then(response => response.data) 

      isKycDataExpired = checkIdExpired(currentKycData?.details)
      // expiredPartyId = currentKycData.details.connectedParty.filter( party => checkIdExpired(party))

      console.log(currentKycData)
      console.log('currentKycData')

      setAllManagers(allManagers) 
      setKycData(currentKycData)
      

      let kycRiskDataList = await getkycRiskAssessment(userId, token).then(response => response.data);

      console.log(kycRiskDataList)
      console.log('kycRiskDataListkycRiskDataListkycRiskDataList')

      let kycRiskData = kycRiskDataList[0]
      console.log(kycRiskDataList)
      console.log('kycRiskDataList')
      setAllFormData(kycRiskDataList)
      let pepResultsFull = [], crimeResultsFull =[], highRiskJursdictionResultFull =[], sanctionsResultsFull =[], adverseNewsResultsFull =[]; //this to resolve scope issue
      let pepResultsFullFiltered = [], crimeResultsFullFiltered =[], highRiskJursdictionResultFullFiltered =[], sanctionsResultsFullFiltered =[], adverseNewsResultsFullFiltered =[]; //this to resolve scope issue

      // 1. Call sanctions.io API
      if (kycRiskDataList.length == 0 || fetchDataAgain === true) {
        setAssessmentRunning(true);
        console.log('fetching data for 1st time...')
        setFormData({ kycaml_client_id: userId, created_at: '', selectedDate: '', sourceOfWealthDocumentURL: '', otherDocumentsURL: '', riskLevel: 'Standard', nextRescreeningDate: '', status: 'new', singaporeGovernmentEntity: '', listedOnStockExchange: '', singaporeFI: '', nonSingaporeFI: '', politicallyExposedPerson: '', countryCountermeasureFATF: '', countryInadequateAML_CFT: '', higherRiskSector: '', highRiskJurisdictionFATF: '', credibleBodyIdentifiedCountry: '', investmentVehicle : { response: '', justification: '', newComment:'', comments:[] }, customerPensionSuperannuation: { response: '', justification: '', newComment:'', comments:[]}, unverifiedBusinessActivity: { response: '', justification: '', newComment:'', comments:[] }, unverifiedCorporateStructure: { response: '', justification: '', newComment:'', comments:[] }, unverifiedFinancialActivity: { response: '', justification: '', newComment:'', comments:[] }, unusualOwnershipStructure: { response: '', justification: '', newComment:'', comments:[] }, unusualBusinessCircumstances: { response: '', justification: '', newComment:'', comments:[] }, nomineeShareholdersBearer: { response: '', justification: '', newComment:'', comments:[] }, anonymousTranscations: { response: '', justification: '', newComment:'', comments:[] }, unknownPaymentSources: { response: '', justification: '', newComment:'', comments:[] }, pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJursdictionHits:[], adverseNewsHits:[], });
        setLoaded(false)

        console.log(formData)

        individualOrEntityConnectedPartyToCheck = [currentKycData] // this convert entity details into array, to concat the connected parties below
        // console.log(individualOrEntityConnectedPartyToCheck)
        // console.log('individualOrEntityConnectedPartyToCheck')

        if (currentKycData.type == 'entity' && currentKycData?.details?.connectedParty.length != 0) {
          individualOrEntityConnectedPartyToCheck = individualOrEntityConnectedPartyToCheck.concat(currentKycData?.details?.connectedParty);  // merge arrays, to gather all additional connected parties
        } 
        // console.log(individualOrEntityConnectedPartyToCheck)

        individualOrEntityConnectedPartyToCheck.map( async (entityOrParty, index) => {
          // 1a. Adjust entity search type
          let partyType = 'individual' // currentKycData.type
          if (currentKycData.type == 'entity' && index == 0) { 
            partyType = 'entity'
          }

          let relevantPartyType = ''
          if (index !== 0) {
            relevantPartyType = ` (Relevant Party ${index})` //let relevantPartyTypes = [' (Relevant Party)'];  // if (entityOrParty.authorisedSignatory) { //     relevantPartyTypes.push('(authorisedSignatory)'); // } // if (entityOrParty.connectedParty) { //     relevantPartyTypes.push('(connectedParty)'); // } // if (entityOrParty.ultimateBeneficialOwner) { //     relevantPartyTypes.push('(ultimateBeneficialOwner)'); // } relevantPartyType = relevantPartyTypes.join(' ');
          }

          // 1b. Make API calls & save data in results[]
          const pepPromise = sanctionsHits(entityOrParty.name, 'PEP', partyType, token) 
          const crimePromise = sanctionsHits(entityOrParty.name, 'INTERPOL,FBI', partyType, token) 
          const sanctionsPromise = sanctionsHits(entityOrParty.name, 'SANCTIONS-LISTS', partyType, token) 
          const highRiskJursdictionPromise = sanctionsHits(entityOrParty.name, 'FinCEN', partyType, token) 
          const adverseNewsPromise = adverseNewsHits(entityOrParty.name, ' ', partyType, token) 
          
          let pepResults =[], crimeResults =[], highRiskJursdictionResults =[], sanctionsResults =[], adverseNewsResults =[]; //this to resolve scope issue
          try {
            const results = await Promise.all([pepPromise, crimePromise, highRiskJursdictionPromise, sanctionsPromise, adverseNewsPromise]);
            [pepResults, crimeResults, highRiskJursdictionResults, sanctionsResults, adverseNewsResults] = results.map(result => result || []); // Use empty array if result is undefined
  
            // return new array of objects with selected properties only
            pepResults?.data?.result.map( person => { 
              if (!person.position) { return }
                person?.position.map(position => {
                  let pepData = {
                    name: person.name,
                    si_identifier : person.si_identifier, 
                    title: `${position?.title || ''}, ${position?.organization || ''}` + relevantPartyType, // (connected party),,
                    startDate : position.start_date || 'NA',
                    endDate : position.end_date || 'NA',
                    response: 'No',
                    justification: '',
                    newComment :  '',
                    comments :  []
                  }
                  pepResultsFull.push(pepData)
                })
            }) 
    
            // pepResultsFull.push(...pepResultsArray); 
            // pepResultsFull = pepResultsFull.concat(pepResultsArray);  // The issue here is that you're using a global variable pepResultsFull inside a function scope, but you're not properly updating it. In JavaScript, when you use an assignment operator (=) inside a function for a variable that exists in an outer scope, it creates a new local variable instead of modifying the global one.

            const crimeResultsArray = []
            crimeResults?.data.result.map( crime => {
            // crimeResults?.data?.result.map( crime => {
                  let crimeData = {
                    name: crime.name + relevantPartyType,
                    dataSource: crime.data_source?.name,
                    si_identifier : crime.si_identifier, 
                    federal_register_notice: crime.federal_register_notice,
                    source_information_url: crime.source_information_url,
                    response: 'No',
                    justification: '',
                    newComment :  '',
                    comments : []
                  }
                  crimeResultsArray.push(crimeData)
            })
            crimeResultsFull.push(...crimeResultsArray);

            const sanctionsResultsArray = []
            sanctionsResults?.data.result.map( sanction => {
            // sanctionsResults?.data?.result.map( sanction => {
                  let sanctionsData = {
                    name: sanction.name + relevantPartyType,
                    dataSource: sanction.data_source.name,
                    si_identifier : sanction.si_identifier, 
                    source_list_url: sanction.source_list_url,
                    response: 'No',
                    justification: '',
                    newComment :  '',
                    comments :  []
                  }
                  sanctionsResultsArray.push(sanctionsData)
            })
            sanctionsResultsFull.push(...sanctionsResultsArray);
            
            const highRiskJursdictionResultsArray = [] 
            highRiskJursdictionResults?.data.result.map( highRiskJursdiction => {
              let highRiskData = {
                name: highRiskJursdiction.name + relevantPartyType,
                dataSource: highRiskJursdiction.data_source.name,
                si_identifier : highRiskJursdiction.si_identifier, 
                source_list_url: highRiskJursdiction.source_list_url,
                response: 'No',
                justification: '',
                newComment :  '',
                comments :  []
              }
              highRiskJursdictionResultsArray.push(highRiskData)
            })
            highRiskJursdictionResultFull.push(...highRiskJursdictionResultsArray);

            adverseNewsResults?.data.map( adverseNews => {
              let adverseNewsData = {
                title: adverseNews.title + relevantPartyType,
                url: adverseNews.url,
                description : adverseNews.description,
                response: 'No',
                justification: '',
                newComment :  '',
                comments :  []
              }
              adverseNewsResultsFull.push(adverseNewsData)
            })


          } catch(e) {
            console.error("error fetching sanctions.io data", e)
          } 
      })
        

      await new Promise(r => setTimeout(r, 2700)); // this to handle the async .map (vy right should return promise to await that top level)

      // 1.5, retrieve all past records into 1 list, loop check if most recent results alr exist 
      const customerPastKyc = await getkycCustomerPastRisk(userId, token) 
      console.log('userId')
      console.log(userId)
      console.log(customerPastKyc.data)
      console.log('customerPastKyc')

      let allPastPepHits = []
      let allPastCrimeHits = []
      let allPastSanctionsHits = []
      let allPastHighRiskJursdictionHits = []
      let allPastAdverseNewsHits = []
      if (customerPastKyc.data.length > 1) {

        customerPastKyc.data.forEach( pastKycRecord => {
          allPastPepHits.push(...pastKycRecord.pepHits)
          allPastCrimeHits.push(...pastKycRecord.crimesHits)
          allPastSanctionsHits.push(...pastKycRecord.sanctionsHits)
          allPastHighRiskJursdictionHits.push(...pastKycRecord.highRiskJursdictionHits)
          allPastAdverseNewsHits.push(...pastKycRecord.adverseNewsHits)
        })
  
        console.log('allPastPepHits')
        console.log(allPastPepHits)
 

        pepResultsFullFiltered = pepResultsFull.filter(hit => 
          !allPastPepHits.some(pastHit => pastHit.si_identifier === hit.si_identifier) );
        
        crimeResultsFullFiltered = crimeResultsFull.filter(hit => 
          !allPastCrimeHits.some(pastHit => pastHit.si_identifier === hit.si_identifier) );
        
        sanctionsResultsFullFiltered = sanctionsResultsFull.filter(hit => 
          !allPastSanctionsHits.some(pastHit => pastHit.si_identifier === hit.si_identifier) );
        
        highRiskJursdictionResultFullFiltered = highRiskJursdictionResultFull.filter(hit => 
          !allPastHighRiskJursdictionHits.some(pastHit => pastHit.si_identifier === hit.si_identifier) );

        adverseNewsResultsFullFiltered = adverseNewsResultsFull.filter(hit => 
          !allPastAdverseNewsHits.some(pastHit => pastHit.title === hit.title) );
      }

      

        // 2. automated checks
        try {
          let emptyFormData = ({  kycaml_client_id: userId, created_at: '', // selectedDate: '',
            sourceOfWealthDocumentURL: '', otherDocumentsURL: '', riskLevel: 'Standard', nextRescreeningDate: '', status: 'new', 
            pepHits:[], crimesHits:[], sanctionsHits:[], highRiskJursdictionHits:[], adverseNewsHits:[], });
          
 
        // // consolidate all data from data fetch and save as new riskAssessment entry 
        kycRiskData = {
          ...emptyFormData, 
          pepHits: pepResultsFullFiltered,
          crimesHits: crimeResultsFullFiltered, 
          sanctionsHits: sanctionsResultsFullFiltered, 
          highRiskJursdictionHits: highRiskJursdictionResultFullFiltered,
          adverseNewsHits: adverseNewsResultsFullFiltered,
          // selectedDate: DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
          created_at: DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),           // iife javascript design pattern , Immediately Invoked Function Expression 
          screenedDate :  DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
        } 

        console.log('kycRiskData')
        console.log(kycRiskData)

        await createkycRiskAssessment(kycRiskData, token)
        allFormData.push(kycRiskData)
        setAllFormData(allFormData) 
      } catch(e) {
        console.error("error saving newest KYC Risk data", e)
      }
    } 
      lastSubmittedEmail = kycRiskData?.managerReviewEmail
      setFormData({
        ...kycRiskData,
      })
      setAssessmentRunning(false);
      if (fetchDataAgain || kycRiskDataList.length == 0) {
        toast.info(`Assessing complete for: ${DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss")}`);
      }
      setLoaded(false);
      setFetchDataAgain(false)
    } 

    fetchData()
    // checkIdExpired(kycData)
  }, // console.error('Error fetching KYC data:', error);
  [fetchDataAgain]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
      // let kycRiskData = kycRiskDataList.filter( kycRisk => kycRisk.created_at === DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss") )[0];
    const displayForm = allFormData.filter(pastFormData => pastFormData.created_at == value)[0]
    console.log(value)
    setFormData(displayForm)

    toast.success ("Changed to: " + value);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newFormData = {  // instead of setState, prevData => {...prevData, new: newValue}
      ...formData,         // do this, to ensure is kept up to date
      [name] : value  
    }
    console.log(newFormData)

    setFormData(newFormData) 
    checkRiskStatus(newFormData)
  }
 
  const handleNestedInputChange = (e, nestedName) => {
    const { name, value } = e.target; 

    const newFormData = {  // instead of setState, prevData => {...prevData, new: newValue}
      ...formData,         // do this, to ensure is kept up to date
      [name] : {
        ...formData[name],
        [nestedName] : value
      }  
    }
    setFormData(newFormData) 
    console.log(newFormData)

    checkRiskStatus(newFormData)
  }

  const handleNestedArrayInputChange = (e, nestedName, index) => {
    let { name, value } = e.target; 
    name = name.split(' ')[0]
    const newArrayResult = [...formData[name]]
    newArrayResult[index][nestedName] = value

    const newFormData = {  
      ...formData,          
      [name]: newArrayResult
    }
    setFormData(newFormData) 
    checkRiskStatus(newFormData)
    console.log(newFormData) 
  }

  const handleSubmitNestedArrayComment = async (e, formName, nestedName, index) => {
    if (formData[formName][index][nestedName].trim() == '') { return }
 
    const newCommentEntry = {
      created_at: formData.created_at,
      kycaml_client_id: userId,
      question: formName + ' ' + index.toString(),
      content : formData[formName][index][nestedName],
      name : decodedToken.name,
      commented_at : DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
      comment_id : uuidv4() //random ID, for future if allow deletion
    }
    await createKycNestedSanctionComment(newCommentEntry, token)   
    // console.log(formName, index, nestedName) // pepHits 0 newComment

    formData[formName][index].comments.push(newCommentEntry);
    const newCommentArray = formData[formName][index].comments

    const newPepArray = [...formData[formName]]
    newPepArray[index].comments = newCommentArray
    newPepArray[index].newComment = ''

    setFormData((prevState) => ({
      ...prevState,
      [formName]: newPepArray
  }));

  }


  const handleSubmitComment = async (e, nestedName, inputName) => {
  //   if (formData[inputName][nestedName].trim() == '') { return }

  //   const newCommentEntry = {
  //     created_at: formData.created_at,
  //     kycaml_client_id: userId,
  //     question: inputName,
  //     content : formData[inputName][nestedName],
  //     name : localStorage.getItem('name'),
  //     commented_at : DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
  //     comment_id : uuidv4() //random ID, for future if allow deletion
  //   }
  //   await createKycComment(newCommentEntry, token)  
  //   formData[inputName].comments.push(newCommentEntry);

  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [inputName]: {
  //         ...prevState[inputName],  // Keep existing nested object properties
  //         [nestedName]: formData[inputName].comments,     // .push should be on its own satement, not assignment
  //         // [nestedName]: [...formData[inputName].comments, newCommentEntry],   // push mutates the array, so runs double
  //         newComment: ''   
  //     }
  // }));

  }

  const handleSaveForm = async (e) => {  
    updatekycRiskAssessment(formData, token)
 
    const newKycData  = {
      ...kycData,
      userId: userId,
      updateForm: 'OnGoingMonitoring',
      details: {
        ...kycData.details, // Spread kycData.details directly into newKycData.details
        identificationExpiryDate: kycData?.details?.identificationExpiryDate,
        identificationDocumentLink: kycData?.details?.identificationDocumentLink
      }
    };

    updatekyc(newKycData, token)
    toast.success(`Assessment saved!`);
  }   


  const handleResetClient = async () => {
    // Retrieve kyc pg1 details, create it again
    const duplicateKycData = {
      ...kycData,
      updatedDate: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss'),
      submitedDate: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss'),
      formStatus: FormStatusEnum.PENDING_CLIENT_DETAILS_1,
      maxPage: 1,
    }
    delete duplicateKycData._id
    delete duplicateKycData.nextRescreeningDate
    delete duplicateKycData.onGoingMonitoring

    try {
      await createkyc(duplicateKycData, token)
      // toast.success("New Customer record created!")
      window.location.href = '/kyc-aml/customer-list';
    } catch(e) {
      console.log(e)
      toast.error("Something went wrong...")
    }
  }

  // const checkFormValidity = () => {
  //   const isValid = Object.values(formData).every(value => value.trim() !== '');

  //   if ()
  //   setIsFormValid(isValid);
  // };

  const handleSubmitForReview = async (e) => { 
    let newFormData = {
      ...formData,
      status: 'waiting for review', 
      submittedBy: decodedToken.name
    }

    console.log(newFormData)

    // submittedBy
    updatekycRiskAssessment(newFormData, token)
    updatekycStatus({formStatus: "pending_manager_review", id: userId }, token)
    toast.success(`Submitted for review!`);

  //   sendEmailNotification({
  //     email: formData.managerReviewEmail,
  //     subject: "KYC Risk Assessment Form pending review - iCompass",
  //     message: `
  //       <div style="max-width: 600px; margin: 0 auto; border: 2px solid #FF8C00; padding: 20px; text-align: center;">
  //         <h1 style="margin-bottom: 20px; text-decoration: underline;"> Risk Assessment Approval Pending</h1>
  //         <p>${decodedToken.name} has submitted a risk assessment form for your approval. 
  //           <a href='${window.location.href}'> Click to view form </a>
  //         </p>
  //         <img src="https://icompass-backend-dev-fhagh5hccpf2ebh9.southeastasia-01.azurewebsites.net/images/from_icompass" alt="iCOMPASS logo" style="display: block; margin: 20px auto; width: 200px; height: auto; max-width: 100%;">
  //         <p style="font-style: italic; margin-top: 20px;">Please do not reply to this email</p>
  //       </div> `
  //   })

  //   createNotification({
  //     type: 'KYC',
  //     text: `${kycData.name} ongoing monitoring needs a review`, 
  //     date: DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss')
  // }, token)
  }  

  const handleIdentificationIdDateChange = (e) => {
    const { name, value } = e.target;
    let newKycData = {...kycData}
    newKycData.details.identificationExpiryDate = value
    setKycData(newKycData)  
    setKycDataIdentity({...kycDataIdentity, identificationExpiryDate: value}) 
    console.log(newKycData)
    console.log(JSON.stringify(newKycData))
    console.log('newKycData__ID')
  }

  const handleIdentificationIdFileChange = async (e, fileType) => {
    const file = e.target.files[0];
    const {name} = e.target
    console.log(name)
    if (!file) { return }

    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${file.name}`; 
      const res = await uploadFile(data, filename,token); 
      console.log(res.data.url)

      // kycData.details = res.data.url  
      console.log('id file change')

      kycData.details.identificationDocumentLink = res.data.url  
      setKycData(kycData)
      setKycDataIdentity({...kycDataIdentity, identificationDocumentLink: res.data.url  }) 

      console.log(kycData)
      console.log('newKycData__file')
    } catch (e) {
      console.log(e)
      toast.error(`Error in uploading file`);
    }
  } 

  const handleFileChange = async (e, fileType) => {
    const file = e.target.files[0];
    const {name} = e.target
    console.log(name)
    if (!file) { return }

    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${file.name}`;
      const res = await uploadFile(data, filename,token); 
      console.log(res.data.url)
      setFormData(prevState => ({
        ...prevState,
        [name] :  res.data.url  
    }))
    console.log(formData)

    } catch (e) {
      console.log(e)
      toast.error(`Error in uploading file`);
    }
  }

  const checkRiskStatus = (newFormData) => { 
    // 1. if all no, AND SDD > 0, risk = 'low'
    const SDD_Questions = ['singaporeGovernmentEntity', 'listedOnStockExchange', 'singaporeFI', 'nonSingaporeFI', 'investmentVehicle', 'customerPensionSuperannuation']
    let SSD_yes_count = 0 


    SDD_Questions.forEach(SSD_qn_key => {
      if (newFormData[SSD_qn_key] == 'Yes' || newFormData[SSD_qn_key]?.response == 'Yes' ) { SSD_yes_count++ }  //  const SSD_yes_count = SDD_Questions.reduce((count, question) => formData[question] === 'Yes' ? count + 1 : count, 0);
    })  
    
    // 2a. if EDD > 0, risk = 'high' instantly, even if justified
    const EDD_Questions = ['politicallyExposedPerson', 'countryCountermeasureFATF', 'countryInadequateAML_CFT', 'unverifiedBusinessActivity', 'unverifiedCorporateStructure', 'unverifiedFinancialActivity']
    let EDD_yes_count = 0 

    EDD_Questions.forEach(EDD_qn_key => { 
      if (newFormData[EDD_qn_key]?.response == 'Yes' || newFormData[EDD_qn_key] == 'Yes') { EDD_yes_count++ }
    })

    // 2b. if Yes high, else justified = medium
    const EDD_Hits_Questions = ['pepHits', 'crimesHits', 'sanctionsHits', 'highRiskJursdictionHits', 'adverseNewsHits']
    let EDD_Hits_yes_count_Justified = 0
    let EDD_Hits_yes_count_NotJustified = 0
    
    EDD_Hits_Questions.forEach(EDD_qn_justification_key => {
      newFormData[EDD_qn_justification_key].forEach(hit => {
        if (hit?.response === 'Yes' && hit?.justification.trim() == '') { 
          EDD_Hits_yes_count_NotJustified++ 
        } else if (hit?.response === 'Yes' && hit?.justification.trim() !== '') { 
          EDD_Hits_yes_count_Justified++ 
        }
      })
    })

    // 3a. if AA > 0, risk = 'Medium', "High risk if yes and unjustified. but medium risk if justified"
    const AA_Questions = ['unusualOwnershipStructure', 'unusualBusinessCircumstances', 'nomineeShareholdersBearer', 'anonymousTranscations', 'unknownPaymentSources']
    let AA_yes_count_Justified = 0
    let AA_yes_count_NotJustified = 0
 
    AA_Questions.forEach(AA_qn_key => {
      if (newFormData[AA_qn_key]?.response === 'Yes' && newFormData[AA_qn_key]?.justification.trim() == '') { 
        AA_yes_count_NotJustified++ 
      } else if (newFormData[AA_qn_key]?.response === 'Yes' && newFormData[AA_qn_key]?.justification.trim() !== '') { 
        AA_yes_count_Justified++ 
      }
    })

    // 3b. if AA_automated > 0 , Risk = 'High',      
    const AA_automated_Questions = ['higherRiskSector', 'highRiskJurisdictionFATF', 'credibleBodyIdentifiedCountry' ]
    let AA_automated_yes_count = 0

    AA_automated_Questions.forEach(AA_automated_qn_key => { 
      if (newFormData[AA_automated_qn_key]?.response == 'Yes' || newFormData[AA_automated_qn_key] == 'Yes') { AA_automated_yes_count++ }
    })

    // 2a. if EDD > 0, risk = 'high' instantly, even if justified
    // 3b. if AA_automated > 0 , Risk = 'High',      

    // 3a. if AA > 0, risk = 'Medium', "High risk if yes and unjustified. but medium risk if justified"
    // 2b. if Yes high, else justified = medium

    // chatgpt this to see how simplify logic more elegant, eg allCountsZero()
    let riskLevel = 'Standard'
    if (EDD_yes_count === 0 && EDD_Hits_yes_count_Justified == 0 && EDD_Hits_yes_count_NotJustified == 0
      && AA_automated_yes_count == 0 && AA_yes_count_Justified == 0 && AA_yes_count_NotJustified == 0
      && SSD_yes_count > 0) {
        riskLevel = 'Low'
      } 
    else if (EDD_yes_count > 0 || EDD_Hits_yes_count_NotJustified > 0 || AA_automated_yes_count > 0 || AA_yes_count_NotJustified > 0 ) {
        riskLevel = 'High';
    }  
    else if (EDD_yes_count === 0 && EDD_Hits_yes_count_Justified == 0 && EDD_Hits_yes_count_NotJustified == 0
      && AA_automated_yes_count == 0 && AA_yes_count_Justified == 0 && AA_yes_count_NotJustified == 0
      && SSD_yes_count == 0) {
        riskLevel = 'Standard'
    }
    else if (EDD_Hits_yes_count_Justified > 0 ||  AA_yes_count_Justified > 0) {
      riskLevel = 'Standard';
    }  
    else {
      riskLevel = "edge case!"
    }

    console.log('SSD_yes_count:', SSD_yes_count);
    console.log('EDD_yes_count:', EDD_yes_count);
    console.log('EDD_Hits_yes_count_Justified:', EDD_Hits_yes_count_Justified);
    console.log('EDD_Hits_yes_count_NotJustified:', EDD_Hits_yes_count_NotJustified);
    console.log('AA_automated_yes_count:', AA_automated_yes_count);
    console.log('AA_yes_count_Justified:', AA_yes_count_Justified);
    console.log('AA_yes_count_NotJustified:', AA_yes_count_NotJustified);
    console.log("the risk level is: ", riskLevel)

    newFormData = {
      ...newFormData,
      riskLevel : riskLevel  
    }
    setFormData(newFormData)
    console.log( newFormData)
  }

  const getRiskTag = (risk) => {
    const riskClasses = {
      Low: 'text-green-500',
      Standard: 'text-orange-500',
      High: 'text-red-500',
      Edge: 'text-purple-600', 
    };
    const riskClass = riskClasses[risk] || riskClasses['Edge']; // Default to 'Edge' for unknown risks
    return <span className={`bg-gray-200 py-2 px-4 border border-b-gray-300 ${riskClass}`}>{risk}</span>;
  };

  const getStatusTag = (status) => {
    if (status == "new" || status == "waiting for review") {
      return <span className="text-orange-500"> Yet to be decided by manager</span> 
    }
    else if (status == 'accepted') {
      return <span className="text-green-500">Accepted</span> 
    }
    else if (status == 'rejected') {
      return <span className="text-red-500">Rejected</span> 
    } else {
      return <span className="text-purple-600">Edge case!!!</span> 
    }
  }


  const handleStatusUpdate = (e, updatedStatus) => {

      let nextRescreeningDate = '-'
      let currentDate = DateTime.now().setZone('Asia/Singapore');

      if (updatedStatus == 'accepted') {
        if (formData.riskLevel == 'Low') {  nextRescreeningDate = currentDate.plus({ years: 3 }).toFormat("dd-MM-yyyy") } 
        if (formData.riskLevel == 'Standard') {  nextRescreeningDate = currentDate.plus({ years: 2 }).toFormat("dd-MM-yyyy") } 
        if (formData.riskLevel == 'High') {  nextRescreeningDate = currentDate.plus({ years: 1 }).toFormat("dd-MM-yyyy") } 
      }

      const newFormData = {
          ...formData,
          status : updatedStatus,
          screenedDate :  DateTime.now().setZone('Asia/Singapore').toFormat("dd-MM-yyyy, HH:mm:ss"),
          nextRescreeningDate: nextRescreeningDate
        }

      setFormData(newFormData)
      updatekycStatus({formStatus: updatedStatus, id: userId, nextRescreeningDate : nextRescreeningDate }, token)

      updatekycRiskAssessment(newFormData, token)
      console.log(newFormData)
  }
  
  let qnCounter = 1

  return (
    <div> 
    
        {loaded ? "loading..." : (
          <div> 
         <Card className="mt-8">
         <div className='flex justify-between'>
          <span className='mb-1 font-semibold underline text-xl'> On Going Monitoring </span>  
          <select value={formData.created_at}  onChange={handleDateChange} name='selectedDate'className="w-5/12 p-1.5 mb-5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none  mr-6 focus:border-green-500">
          <option disabled> - History - </option>
            {allFormData.map( (pastFormData) => (
            <option value={pastFormData.created_at}> {pastFormData.created_at}  ({pastFormData.riskLevel}, {pastFormData.status}) </option>
          ))}
          </select>
    
    
          {assessmentRunning ? (
              <div>Running assessment now...</div>
            ) : (
              <>
              {/* // <Tooltip title={<p style={{ fontSize: "15px" }}>Warning! Comments will not be carried over to newest report</p>} arrow> */}
                <button onClick={()=>setFetchDataAgain(true)} className={`bg-green-400 mx-2 hover:bg-green-600 text-white font-semibold py-0.5 px-2 rounded-lg mb-4 w-3/12 ${assessmentRunning ? 'invisible transition-invisible duration-300' : ''}`} disabled={assessmentRunning}>
                  Click to Simulate next month assessment
                </button>
              {/* // </Tooltip> */}
                <button  onClick={handleResetClient} className='mx-2 bg-green-500 hover:bg-green-600 text-white font-semibold py-0.5 px-2 rounded-lg mb-4 w-3/12'>
                  Click to reset customer
                </button>
              </>
            )}
    
    
        </div>
        </Card>
        <>
          {/* EDD qn13-17 */}
          <SanctionsioScreeningCard  disabled={disabled} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
            inputName={"pepHits"} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for PEP screening?"} 
            tableHeaders={[
              {displayTitle: 'Name/title', width : 'w-3/12', dataField: (result) => `${result.name} \n ${result.title}` },
              {displayTitle: 'Position start', width : 'w-1.5/12', dataField: (result) => result.startDate },
              {displayTitle: 'Position end', width : 'w-1.5/12', dataField: (result) => result.endDate },
              {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
              {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
            ]}
          />
          <SanctionsioScreeningCard  disabled={disabled} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
            inputName={"crimesHits"} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for crime?"} 
            tableHeaders={[
              {displayTitle: 'Name / DataSource', width : 'w-2/12', dataField: (result) => `${result.name} \n ${result.dataSource}` },
              {displayTitle: 'Remarks', width : 'w-4/12', 
                dataField: (result) => {
                  return ( // you can either return a plain string like above, or return <> JSX </> with 'return'
                    <>
                      {result.federal_register_notice.substring(0, 280) + (result.federal_register_notice.length > 280 ? '...' : '')} {/* 280 characters approx 70 words */}
                      <a className='text-blue-500' href={result?.source_information_url} target="_blank" rel="noopener noreferrer"> Link </a> {/* rel="noopener noreferrer", byppasses the API block somehow */} 
                    </> );
                }}, 
              {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
              {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
            ]}
          />
          <SanctionsioScreeningCard  disabled={disabled} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
            inputName={"sanctionsHits"} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for sanctions?"} 
            tableHeaders={[
              {displayTitle: 'Name', width : 'w-3/12', dataField: (result) => result.name },
              {displayTitle: 'DataSource', width : 'w-3/12',
                  dataField: (result) => {
                    return ( 
                      <>
                        {result.dataSource} 
                        <a className='text-blue-500' href={result?.source_list_url} target="_blank" rel="noopener noreferrer"> Link </a>
                      </> );
                    }},
              {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
              {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
            ]}
          />
          <SanctionsioScreeningCard disabled={disabled} questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
            inputName={"highRiskJursdictionHits"} handleInputChange={handleNestedArrayInputChange} question={"Were there any hits for High Risk Jurisdiction?"} 
            tableHeaders={[
              {displayTitle: 'Name', width : 'w-3/12', dataField: (result) => result.name },
              {displayTitle: 'Data Source', width : 'w-3/12', 
                dataField: (result) => {
                  return ( 
                    <>
                      {result.dataSource} 
                      <a className='text-blue-500' href={result?.source_list_url} target="_blank" rel="noopener noreferrer"> {result.source_list_url} </a>
                    </> );
                  }},
              {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
              {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
            ]}
          />
          <SanctionsioScreeningCard disabled={disabled}  questionNumber={qnCounter++} handleSubmitComment={handleSubmitNestedArrayComment} result={formData} 
            inputName={"adverseNewsHits"} handleInputChange={handleNestedArrayInputChange} question={"Are there any adverse news?"} 
            tableHeaders={[
              {displayTitle: 'Title', width : 'w-2.5/12', dataField: (result) => result.title },
              {displayTitle: 'Description', width : 'w-3.5/12', 
                dataField: (result) => {
                  return ( 
                    <>
                      {result.description} 
                      <a className='text-blue-500' href={result?.url} target="_blank" rel="noopener noreferrer"> Link </a>
                    </> );
                  }},
              {displayTitle: 'True Hit?', width : 'w-1/12', dataField: null },
              {displayTitle: 'Justification', width : 'w-5/12', dataField: null },
            ]}
          /> 
 
        </>
            


        {isKycDataExpired  && kycData.type =='individual' &&
         <Card>
            <p className='mb-2 font-semibold'> Identity Document Expiration </p> <hr/>
            <p className='my-3'> <span className='text-red-500'> Note: </span> The document for (Individual, {kycData.name}) has expired, please upload a new document. </p> 
            {/*  need update kyc */}
            <p className='font-medium mb-2'> New Expiry Date (If Applicable): </p>

            <input type="date" name="identificationExpiryDate" value={kycDataIdentity.identificationExpiryDate} onChange={(e) => handleIdentificationIdDateChange(e)} className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" /> <br/>
            {kycDataIdentity.identificationDocumentLink && ( <a href={kycDataIdentity.identificationDocumentLink} className='text-blue-600'> {kycData?.details?.identificationDocumentLink.split('/').slice(-1)[0]} </a> )} <br/>
            <input type="file" name="identificationDocumentLink" onChange={(e) => handleIdentificationIdFileChange(e)} className="pt-3"/>
        </Card>  
        }

 
        {kycData.type =='entity' &&
        //  filter logic, then .map
        kycData.details.connectedParty.map( (party, index) => (
          checkIdExpired(party) ? (
          <Card>
            {console.log(checkIdExpired(party))}
            {console.log('kycData--parties')}

            <p className='mb-2 font-semibold'> Identity Document Expiration </p> <hr/>
            <p className='my-3'> <span className='text-red-500'> Note: </span> The document for (Relevant Party {++index}, {party.name}) has expired, please upload a new document. </p> 
            <p className='font-medium mb-2'> New Expiry Date (If Applicable): </p>
            {/* now need to mass update the connected party documents */}

            {/* then when send to backend, able to update the array */}

            {/* current format is  
            details : [{}]

            where each connectedParty obejct is directly party.identificationExpiryDate
            where each connectedParty obejct is directly party.identificationDocumentLink
            
            1 way is to save all as array then replace
            
            */}

            <input type="date" name="identificationExpiryDate" value={kycDataIdentity.identificationExpiryDate} onChange={(e) => handleIdentificationIdDateChange(e)} className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" /> <br/>
            {kycDataIdentity.identificationDocumentLink && ( <a href={kycDataIdentity.identificationDocumentLink} className='text-blue-600'> {kycData?.details?.identificationDocumentLink.split('/').slice(-1)[0]} </a> )} <br/>
            <input type="file" name="identificationDocumentLink" onChange={(e) => handleIdentificationIdFileChange(e)} className="pt-3"/>
          </Card>
        ) : ''))
        }


    
          <Card> 
            <p className='mb-2 font-semibold'> Reassessed Risk Level  </p> <hr/>
            <p className='my-4'> The reassessed risk level is: {getRiskTag(formData?.riskLevel || 'Standard')} </p>
            <SubmissionCard token={token} getRiskTag={getRiskTag} formData={formData} setFormData={setFormData} handleFileChange={handleFileChange} 
              getStatusTag={getStatusTag} handleStatusUpdate={handleStatusUpdate} handleSaveForm={handleSaveForm} 
              handleInputChange={handleInputChange} currentUrl={currentUrl} userRole={userRole} 
              allManagers={allManagers} handleSubmitForReview={handleSubmitForReview} userId={userId}/>
        </Card>
    
      </div>
      )}
    </div>
      );
    };
    


export default _RiskAssessment 
 

function checkIdExpired(currentKyc) {
  const expireDate = currentKyc?.identificationExpiryDate
  let expired = false;

  if (!expireDate) { return expired }
  const givenDate = DateTime.fromFormat(expireDate , "yyyy-MM-dd"); // const givenDate = DateTime.fromFormat(expireDate , "dd-MM-yyyy"); order of yy-dd-mm when converting lol...
  const diff = Math.round(givenDate.diff(DateTime.now(), 'days').days);
  if (diff < 0) { expired = true } 

  return expired
}