import React, { useState, useEffect } from "react"; 

function SanctionsioScreeningCard({  disabled, handleSubmitComment,tableHeaders, pepExtra, handleInputChange, questionNumber, question, inputName, questionKey, result, justification }) {

  
  if (result[inputName]) {
    result = result[inputName] 
  } else {
    result = [] //['too many']
  }

  let unjustifiedHit = 0
  let justifiedHit = 0
  // if too many hits, this throw error
  result.forEach(hit => {
    if (hit?.response === 'Yes' && hit?.justification.trim() == '') { 
      unjustifiedHit++ 
    } else if (hit?.response === 'Yes' && hit?.justification.trim() !== '') { 
      justifiedHit++ 
    }
  })

  let borderColorClass
  if (unjustifiedHit > 0) {
    borderColorClass = "border border-red-500"
  } else if (justifiedHit > 0) {
    borderColorClass = "border border-orange-300";
  } else if (justifiedHit == 0 && unjustifiedHit==0) {
    borderColorClass = ""
  } else {
    borderColorClass = "border border-purple-500"
  }
 

  return (
   <div className={`bg-white rounded-sm p-4 mb-4 mx-2  ${borderColorClass}`}> 
      <strong>Question {questionNumber}: </strong> {question}
      <div> 
        <div className="d-flex "> 

        <>
        {/* {inputName=='pepHits' && (
            <table className="w-full my-4 text-sm">
              <thead className="bg-slate-400">
                <tr>
                  <th colSpan="6"  className={`px-6 pt-1 underline`}> Response  </th>
                </tr>
                
                <tr>
                  { tableHeaders.map((header, index) => (
                    <th className={`px-6 pb-2 ${header?.width}`}  key={index}> {header?.displayTitle} </th>
                  ))}
                </tr>
              </thead>
              <tbody>
        {pepExtra.details?.pepPerson=='yes' && (
              <tr className="px-6 py-3 border">
                <td className="px-2 py-2 text-center border-b"> {pepExtra?.name} - {pepExtra?.details?.pepPersonCountryOrOrganisation} - {pepExtra?.details?.pepPersonPublicFunctionNature}  (PEP Person) </td>
                <td className="px-2 py-2 text-center border-b"> {pepExtra.details?.pepPersonRoleFrom} </td>
                <td className="px-2 py-2 text-center border-b"> {pepExtra.details?.pepPersonRoleTo} </td>
              </tr>
        )}
        {pepExtra.details?.pepAssociate=='yes' && (
              <tr className="px-6 py-3 border">
                <td className="px-2 py-2 text-center border-b"> {pepExtra.details?.pepAssociateName} - {pepExtra.details?.pepAssociateRelationship} - {pepExtra.details?.pepAssociateCountryOrOrganisation} - {pepExtra.details?.pepAssociatePublicFunctionNature} (PEP Associate)  </td>
                <td className="px-2 py-2 text-center border-b"> {pepExtra.details?.pepAssociateRoleFrom} </td>
                <td className="px-2 py-2 text-center border-b"> {pepExtra.details?.pepAssociateRoleTo} </td>
              </tr>
        )}
        {pepExtra.details?.pepFamily=='yes' && (
              <tr className="px-6 py-3 border">
                <td className="px-2 py-2 text-center border-b"> {pepExtra.details?.pepFamilyName} - {pepExtra.details?.pepFamilyRelationship} - {pepExtra.details?.pepFamilyCountryOrOrganisation} - {pepExtra.details?.pepFamilyPublicFunctionNature} (PEP Family)  </td>
                <td className="px-2 py-2 text-center border-b"> {pepExtra.details?.pepFamilyRoleFrom} </td>
                <td className="px-2 py-2 text-center border-b"> {pepExtra.details?.pepFamilyRoleTo} </td>
              </tr>
        )}
              </tbody>
            </table> 
          )} */}
        </>

          <table className="w-full my-4 text-sm">
            <thead className="bg-gray-200">
              <tr>
                { tableHeaders.map((header, index) => (
                  <th className={`px-6 py-3 ${header?.width}`}  key={index}> {header?.displayTitle} </th>
                ))}
              </tr>
            </thead>
 
            <tbody> 
              {result.length === 0 ? (
              <tr>
                <td colSpan="6" className="px-6 py-3 border-b text-center">No hits data found</td>
              </tr> 

            ) : (result === 'too many') ? ( // (result === 'too many')
              <tr>
                <td colSpan="6" className="px-6 py-3 border-b text-center">Too many results, use a more specific name</td>
              </tr>
            ) : (
              [...result].reverse().map((person, index) => (
                <tr key={index} className="px-6 py-3 border-b">
                    { tableHeaders.map(header => (
                      header?.dataField ? <td className="px-6 py-3"> { header?.dataField(person) || 'NA' }  </td> : ''
                    ))
                    }
                  {/* <td className="px-6 py-3"> { tableHeaders[index].dataField(person) }  </td> */}
                  {/* <td className="px-6 py-3">{person.name}, <br/> ({person.title})</td> */}
                  {/* <td className="px-6 py-3 text-center">{ tableHeaders[index].dataField(person) || 'NA'}</td> */}
                  {/* <td className="px-6 py-3 text-center">{person.start_date || 'NA'}</td> */}
                  {/* <td className="px-6 py-3 text-center">{ tableHeaders[index].dataField(person) || 'NA'}</td> */}
                  {/* <td className="px-6 py-3 text-center">{person.end_date || 'NA'}</td> */}
                  <td  key={index + "b"} className="px-2 py-2 text-center"> 
                    {/* <input type="radio" id="pepNo" name="adverseResponse" value="yes" onChange={handleInputChange} checked={formData.adverseResponse === 'yes'} className="mr-1 w-4 h-4"/> */}
                    <input disabled={disabled} type="radio"name={`${inputName} ${index}`} value="Yes" onChange={(e) => handleInputChange(e, 'response', index)}  checked={result[index]?.response == 'Yes'} className="my-2 w-4 h-4"/>
                    {/* <input type="radio" name={`pepHits ${index}`} value="Yes" onChange={(e) => handleInputChange(e, 'response', index)}  checked={result[index]?.response == 'Yes'} className="my-2 w-4 h-4"/> */}
                    <span> Yes </span> <br/>
                    <input disabled={disabled} type="radio"name={`${inputName} ${index}`} value="No" onChange={(e) => handleInputChange(e, 'response', index)}  checked={result[index]?.response == 'No'} className="my-2 w-4 h-4"/>
                    {/* <input type="radio" name={`pepHits ${index}`} value="No" onChange={(e) => handleInputChange(e, 'response', index)}   checked={result[index]?.response == 'No'} className="my-2 w-4 h-4"/> */}
                    <span> No </span>
                  </td>
                  <td  key={index + "a"} className="px-2 py-3 flex justify-between ">

          <div className='w-full'>
            <div className='flex '>
            {result[index]?.response == 'Yes' &&  (
              <p className="font-semibold mb-1 w-full"> 
                Justification: <br/>
                <textarea disabled={disabled} rows={3} value={result[index]?.justification} placeholder={`Justify your response\n\n\n`} name={`${inputName} ${index} justification`} onChange={(e) => handleInputChange(e, 'justification', index)}  className="border p-1.5 font-normal h-11/12 w-11/12"></textarea>
                {/* <textarea value={result[index]?.justification} placeholder={`Justify your response\n`} name={`pepHits ${index} justification`} onChange={(e) => handleInputChange(e, 'justification', index)}  className="border p-1.5 font-normal h-11/12 w-11/12"></textarea> */}
                {/* <button className="bg-green-500 hover:bg-green-600 text-white text-sm p-1.5"> Update</button> */}
              </p> 
          )}

              <div className="font-semibold mb-1 w-full"> 
                Comments: 
                {result[index].comments.map( (comment) => (
                  <div className='my-1 p-1.5 border'>
                    <p className='text-xs underline font-normal flex justify-between'>
                        <span> {comment.name}: </span> {comment.commented_at}
                    </p>
                    <p className='w-full text-sm break-words'>
                      {comment.content}
                    </p>
                  </div>
                ))} 
                <div className="flex"> 
                  <input disabled={disabled} placeholder="Write comment" name={`${inputName} ${index} comment`} value={result[index]?.newComment} onChange={(e) => handleInputChange(e, 'newComment', index)} className="w-11/12 p-1 font-normal border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500"/>
                  {/* <input placeholder="Write comment" name={`pepHits ${index} comment`} value={result[index]?.newComment} onChange={(e) => handleInputChange(e, 'newComment', index)} className="w-11/12 p-1 font-normal border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500"/> */}
                  <button onClick={(e) => handleSubmitComment(e, inputName, 'newComment', index)} >
                  {/* <button onClick={(e) => handleSubmitComment(e, 'pepHits', 'newComment', index)} > */}
                    <svg width="34px" height="34px" viewBox="-6.24 -6.24 36.48 36.48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
                    <rect x="-6.24" y="-6.24" width="36.48" height="36.48" rx="18.24" fill="#25e44b" strokeWidth="0"/> </g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.288"/>
                    <g id="SVGRepo_iconCarrier"> <path d="M3 7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2Z" stroke="#f7f7f7" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round"/> </g>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          
                  </td>
                </tr>
              ))
            )}
          </tbody>
      </table> 
        </div>
      </div>
    </div>
  );
}

export default SanctionsioScreeningCard;
 