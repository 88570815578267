import React, { useState, useEffect } from 'react';
import { BsChevronDown, BsDownload, BsArchive, BsCopy } from 'react-icons/bs';
import {  Row, } from "../../shared/utils"
import { getCmpAllForms, deleteCmpForm, archiveCmpForm, getCmpFormToCsv } from '../../../API/Api'
import AddEditNewCmpFormModal from "./AddEditNewCmpFormModal";
import Modal from "../../shared/Modal";
import Tooltip from '@mui/material/Tooltip'; 
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { jwtDecode } from "jwt-decode";




const FormMonitoringTable = () => {
  const currentLocation = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]); // switch to displayed cmp from the ALL_cmpFormData

  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false); 
  const [cmpFormData, setCmpFormsData] = useState([]) 
  
  const token = localStorage.getItem('accessToken')
  const decodedToken =  jwtDecode(token)

  useEffect( () => {
    const fetchData = async () => { 
      const formsData = await getCmpAllForms(token)
      setCmpFormsData(formsData.data.filter(form => form.archived === false)) 
      setLoading(false); 
    }
    fetchData()
      }, // console.error('Error fetching KYC data:', error);
  [loading]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setDisplayedUsers( users.filter((user) => user.name.toLowerCase().includes(e.target.value.toLowerCase())) )
  };


  const handleDeleteForm = async (topicId) => {
    try {
      await deleteCmpForm(topicId, token) 
      setLoading(true)
      toast.success("Form deleted!")
    } catch(e) {
      toast.error("Something went wrong...")
      console.log(e)
    }
  }

  const handleArchiveForm = async (topicId) => {
    try {
      await archiveCmpForm(topicId, token) 
      setLoading(true)
      toast.success("Form Archived!")
    } catch(e) {
      toast.error("Something went wrong...")
      console.log(e)
    }
  }



  // modify for statistics to show only most recent

  const calculatePendingStatus = (cmpForm, status) => {
    return cmpForm.subTopics.reduce((statusCount, subTopic) => {
      subTopic.questions.forEach(question => {
        const responseKeys = Object.keys(question.responses);
        if (responseKeys.length > 0) {
          const latestResponseKey = responseKeys[responseKeys.length - 1];
          const latestResponse = question.responses[latestResponseKey];
          if (latestResponse && latestResponse.status === status) {
            statusCount++;
          }
        }
        // Do not add 10 if no response exists; only count actual responses
      });
      return statusCount;
    }, 0);
  };
    // topic?.subTopics.reduce((total, subtopic) => total + (subtopic.questions?.length || 0), 0)
    // return statusCount
  
    const handleCsvDownload = async (cmpFormId) => {
      try {
        const response = await getCmpFormToCsv(cmpFormId, token);
    
        // Create a Blob from the array buffer
        const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'output.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (err) {
        console.error('Download failed:', err);
        toast.error('Download failed...')
      }
    };

  return (
    <div >
      <Row> 
        <div className="w-full flex justify-between items-center p-4 bg-white shadow">
          <strong> Compliance Monitoring Form</strong>
          <input placeholder="Search" value={searchTerm} onChange={handleSearchChange} className="p-2 border border-gray-300 rounded-lg mr-2 w-1/3" />
          <Modal title="Create New Form" ModalTitle="Create New Compliance Monitoring Form ">
            <AddEditNewCmpFormModal setLoading={setLoading} action='Create'/>
          </Modal>

          <Link to='/compliance-monitoring/archived-monitoring' replace={currentLocation.pathname === '/compliance-monitoring/archived-monitoring'}>
            <button className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg">
              View Archives
            </button>
          </Link>

          <button onClick={() => setCollapsed(!collapsed)} className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded-full">
            <BsChevronDown/>
          </button>
        </div>
      </Row> 
      {!collapsed && (
          <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div>
            <table className="w-full border-collapse border border-gray-300 table-auto">
              <thead>
                <tr className="bg-gray-300 text-left">
                  <th className="p-2">Form Name</th>
                  <th className="p-2 text-center bg-green-200">Topic</th>
                  <th className="p-2 text-center bg-blue-200">Pending Action</th>
                  <th className="p-2 text-center bg-blue-200">Pending Review</th>
                  <th className="p-2 text-center bg-blue-200">Reviewed</th>
                  <th className="p-2 text-center ">Last Activity</th>
                  <th className="p-2 text-center">Start Date</th>
                  <th className="p-2 text-center">Expiry Date</th>
                  <th className="p-2 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {cmpFormData.map( (cmpForm, index) => (
                  <tr key={cmpForm._id} className={index % 2 === 0 ? 'bg-green-50 hover:bg-green-100' : 'bg-white hover:bg-green-100'}>
                    <Link to={`/compliance-monitoring/user-form?formId=${cmpForm._id}`} replace={currentLocation.pathname === `/compliance-monitoring/user-form?formId=${cmpForm._id}`}>
                      <Tooltip title={<p style={{ fontSize: "14px" }}> Edit {cmpForm.formName} </p>} className='border-stone-500' arrow>
                        <td className="p-2 w-2.5/12 leading-normal">{cmpForm.formName}</td>
                      </Tooltip> 
                    </Link>

                    <td className="p-2 w-3/12 text-center">{cmpForm.topicName} </td>
                    {/* <td className="p-2 w-2/12 text-center">{cmpForm.totalQuestions} </td>
                    <td className="p-2 w-2/12 text-center">{0}</td>
                    <td className="p-2 w-2/12 text-center">{0}</td> */}

                    <td className="p-2 w-1/12 text-center">{cmpForm.totalQuestions - calculatePendingStatus(cmpForm,'submit')  - calculatePendingStatus(cmpForm,'reviewed') } </td>
                    <td className="p-2 w-1/12 text-center">{calculatePendingStatus(cmpForm,'submit') }</td>
                    <td className="p-2 w-1/12 text-center">{calculatePendingStatus(cmpForm,'reviewed')}</td>
                    <td className="p-2 w-1/12 text-center">{cmpForm.createdAt}</td>
                    <td className="p-2 w-1/12 text-center">{cmpForm.startDate}</td>
                    <td className="p-2 w-1/12 text-center">{cmpForm.expiryDate}</td>
                    <td className="p-2 w-1/12">
                      <div className="flex">
                        <Tooltip title={"Delete"} className='border-stone-500 border px-1.5 pt-1.5 mx-1 bg-red-400 cursor-pointer hover:bg-red-500 transition duration-500 rounded-full' arrow>  
                              <div>
                                <Modal title="Delete Compliance" CustomButton={()=> <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.73708 6.54391V18.9857C5.73708 19.7449 6.35257 20.3604 7.11182 20.3604H16.8893C17.6485 20.3604 18.264 19.7449 18.264 18.9857V6.54391M2.90906 6.54391H21.0909" stroke="#1C1C1C" stroke-width="1.7" stroke-linecap="round"></path> <path d="M8 6V4.41421C8 3.63317 8.63317 3 9.41421 3H14.5858C15.3668 3 16 3.63317 16 4.41421V6" stroke="#1C1C1C" stroke-width="1.7" stroke-linecap="round"></path> </g></svg>}>
                                  <DeleteCmpFormModal handleDeleteForm={handleDeleteForm} form={cmpForm}/>
                                </Modal>
                              </div> 
                          </Tooltip>  

                          <Tooltip title={"Edit"} className='border-stone-500 border px-1.5 pt-1.5 mx-1 bg-green-200 cursor-pointer hover:bg-green-300 transition duration-500 rounded-full' arrow>  
                              <div>
                                <Modal title="Edit Compliance" ModalTitle="Update Form" CustomButton={()=> <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clip-rule="evenodd" d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z" fill="#000000"/> </svg> }>
                                  <AddEditNewCmpFormModal setLoading={setLoading} action='Update' cmpFormId={cmpForm._id}/>
                                </Modal>
                              </div> 
                          </Tooltip>  

                        <Tooltip onClick={() => handleCsvDownload(cmpForm._id)} title={"Download"} className='p-2.5 mx-1 rounded-full cursor-pointer hover:bg-gray-400 border border-stone-500 transition duration-500' arrow> <BsDownload /> </Tooltip> 
                        <Tooltip title={"Archive"} onClick={() => handleArchiveForm(cmpForm._id)} className='p-2.5 mx-1 rounded-full cursor-pointer hover:bg-gray-400 border border-stone-500 transition duration-500' arrow> <BsArchive /> </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        </div>
      )}
    </div>
  );
};


export default FormMonitoringTable

 

const DeleteCmpFormModal = ({handleCloseModal, handleDeleteForm, form}) => {
  return (
    <div className='px-9 py-6'> 
      <p className='mb-5'> Are you sure you want to delete this Compliance?  </p>
      <div className='flex justify-between'>
      <button onClick={() => handleDeleteForm(form._id)} className='px-6 py-2 mr-4 border border-red-700 bg-red-500 hover:bg-red-600 text-white'> Delete </button>
      <button onClick={handleCloseModal} className='px-6 py-2 mx-6 border bg-slate-300'> Cancel </button>
    </div>
  </div>
  )
}