import React from 'react'
import { Card } from '../utils'

const ChangeLog = () => {
  return (
    <>
    <Card>
        <strong className='text-xl mb-2'> ChangeLog </strong>
    </Card>
    
    <Card>
        <ul>
            <strong className='text-lg mb-2'> August 2024 </strong>
            <li> - Add “Singapore” as default selected nationality option </li>
            <li> - Add remove uploaded files feature  </li>
            <li> - Add sidebar feedback link </li>
            <li> ---- </li>
            <li> - Fix email not sent </li>
            <li> - Add "Viewer" role </li>
            <li> - Add notification in top menu  </li>
        </ul>
    </Card>
    </>
  )
}

export default ChangeLog


 
