import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Card } from '../utils';
import { jwtDecode } from 'jwt-decode';
import { uploadFile } from '../../../API/Api'
import {getCompanyDetails, updateCompanyDetails} from '../../../API/Api'
import { toast } from 'react-toastify';
import { UploadFileInput } from '../utils';

const CompanyProfile = () => {
  //  this need to fetch from superadmin db? or just 1 unique collection in 'user'
  //  kiv superadmin dashboard
  const token = localStorage.getItem('accessToken')
  const decodedToken = jwtDecode(token)
 

  const { register, handleSubmit, reset, watch,setValue, formState: {errors, isSubmitting} } = useForm({
    defaultValues: {
      companyId: '', type: 'company', companyName: "", uenNumber: "", incorporationDate: "", principleBusinessActivity: "", 
      listedCompany: '', governmentLinkedEntity: '', incorporationCertificateUrl: "", companyPolicyDocumentUrl: "", logoUrl: ""
    }
  });


  useEffect(() => {
    const fetchData = async () => {
      const companyData = await getCompanyDetails( 'company', token)
      reset(companyData.data); 

      setImage(companyData.data.logoUrl);
    };
    fetchData();
  }, [reset]);
  

  const onSubmit = async (data) => { 
    console.log(data)
    try {
      await updateCompanyDetails(data, token)
      toast.success('Company Profile successfully updated!')
    }catch(e) {
      toast.error('Something went wrong...')
    }
  };


  const [image, setImage] = useState(null);

  const handleImageUpload = async (e) => {

    const file = e.target.files[0];
    if (!file) { return }

    const reader = new FileReader();
    reader.onload = (event) => {
      setImage(event.target.result);
      setValue('file', file);  // set the file to the form value
      // setValue('image', file);  // set the file to the form value
    };
    reader.readAsDataURL(file);
     
    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${'company_logo'}_${file.name}`; 

      const res = await uploadFile(data, filename, token); 

      console.log(res.data.url)
      console.log('res.data.url')
      setValue('logoUrl', res.data.url);

    } catch (e) {
      toast.error("Error in uploading file")
    }


  }



  const inputClass = "w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500";
  const labelClass = "block text-sm font-medium text-gray-700";

  return (
    <Card>
      <h1 className="text-2xl font-bold text-center mb-6">Company Profile</h1>
      <form onSubmit={handleSubmit(onSubmit)} >
      
        <div className="flex justify-center mb-6">

          <label htmlFor="imageUpload" className="w-32 h-32 bg-gray-300 rounded-full overflow-hidden cursor-pointer hover:opacity-80 transition-opacity duration-300 flex items-center justify-center">
          {image ? (
              <img src={image} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            ) : (
              'Upload Image'
            )}
          </label>

         <input id="imageUpload" type="file" accept="image/*" className="hidden" {...register('logoUrl', { onChange: handleImageUpload })} /> 
        
           {/* <input {...register('logoUrl', { onChange: (e) => { handleFileUpload(e, formName) } })
          } disabled={readOnly} type="file" name={formName} accept={"image/*"} /> */}
        <div/>


        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>  
          <label className={labelClass}>Company Name</label> 
          <input {...register('companyName')} className={inputClass} />
        </div>

        <div>
          <label className={labelClass}>UEN Number</label>
          <input {...register('uenNumber')} placeholder="Enter UEN Number" className={inputClass} />
        </div>

        <div>
          <label className={labelClass}>Incorporation Date</label>
          <input {...register('incorporationDate')} type="date" className={inputClass} />
        </div>

        <div>
          <label className={labelClass}>Principle Business Activity</label>
          <input {...register('principleBusinessActivity')} className={inputClass} />
          {/* <select {...register('principleBusinessActivity')} className={inputClass}>
            <option value="">Select Principle Business Activity</option>
          </select> */}
        </div>

        <div>
          <label className={labelClass}>Listed Company</label>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input {...register('listedCompany')} type="radio" value="Yes" className="form-radio" />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input {...register('listedCompany')} type="radio" value="No" className="form-radio" />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>

        <div>
          <label className={labelClass}>Government linked entity</label>
          <div className="mt-2">
            <label className="inline-flex items-center">
              <input {...register('governmentLinkedEntity')} type="radio" value="Yes" className="form-radio" />
              <span className="ml-2">Yes</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input {...register('governmentLinkedEntity')} type="radio" value="No" className="form-radio" />
              <span className="ml-2">No</span>
            </label>
          </div>
        </div>


          <div>
            <UploadFileInput setValue={setValue} register={register} watch={watch} formDisplayName={'Certificate of Incorporation'}
              formName={'IncorporationCertificateUrl'}/>
          </div>

          <div>
            <UploadFileInput setValue={setValue} register={register} watch={watch} formDisplayName={'Company Policy Documents'}
              formName={'companyPolicyDocumentUrl'} />
          </div>
 

        <div className="md:col-span-2 flex justify-end mt-6">
          <button type="submit" disabled={isSubmitting} className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600">
              {isSubmitting ? 'Updating...' : 'Update Company Info'}
          </button>
        </div>
        </div>
      </form>
    </Card>
  );
};

export default CompanyProfile;