import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { getkycAll } from '../../../API/Api'
import { jwtDecode } from "jwt-decode";
import { useQuery } from '@tanstack/react-query';
          
import {
  Chart as ChartJS, CategoryScale, LinearScale,BarController,
  BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  Title, Tooltip, Legend, CategoryScale, LinearScale, BarElement, BarController );


const RiskRatingCounterChart = () => { 
  const token = localStorage.getItem('accessToken')
  const companyId = ''

  const fetchKycData = async (companyId, token) => {
    const response = await getkycAll(companyId, token);
    const kycData = response.data;
  
    let highCount = 0;
    let mediumCount = 0;
    let lowCount = 0;
  
    kycData.forEach((item) => {
      const status = item.status;
      if (status === 'accepted') {
        if (item.riskAssesment?.outcome === 'High') {
          highCount++;
        } else if (item.riskAssesment?.outcome === 'Medium') {
          mediumCount++;
        } else if (item.riskAssesment?.outcome === 'Low') {
          lowCount++;
        }
      }
    });
  
    return { high: highCount, medium: mediumCount, low: lowCount };
  };

  const { data: outcomeData, isLoading, error } = useQuery({
    queryKey: ['kycData', companyId, token],
    queryFn: () => fetchKycData(companyId, token),
  } );

  

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  const outcomesData = {
      labels: ['Low', 'Medium', 'High'],
      datasets: [
          {
            label: 'Risk Rating',
            data: [outcomeData.low, outcomeData.medium, outcomeData.high,6],
            backgroundColor: [
              '#2FC560', // Low
              'rgba(255, 160, 65, 0.5)', // Medium
              'rgba(255, 0, 0, 0.5)', , // High
            ],
          },
        ]
    };

  return (
    <div>
      <Bar
        data={outcomesData}
        height={500}
        width={700}
        options={{
          responsive: true,
          scales : {
            yAxes: [{
              beginAtZero: true,
              stepSize: 1,
              precision: 0,
              tickInterval: 1
            }]
          },
          maintainAspectRatio: true
          }}
      />
    </div>
  )};

export default RiskRatingCounterChart;
