import React from 'react'; 
import _FormMonitoringTable from './form_user_table/_FormMonitoringTable'
import _ComplianceTopicMonitoringTable from './topics_monitoring_table/_ComplianceTopicMonitoringTable'
import { jwtDecode } from 'jwt-decode';

const __ComplianceTable = () => {

  return (
    <div >
      <br/>  
      <_ComplianceTopicMonitoringTable /> 
      <br/> <br/>
      <_FormMonitoringTable /> 
    </div>
  );
};


export default __ComplianceTable