import React, { useState, useEffect } from 'react';
import { BsChevronDown,  BsCopy } from 'react-icons/bs';
import { Row } from '../../shared/utils';
import Modal from "../../shared/Modal";
import Tooltip from '@mui/material/Tooltip'; 
import { getCmpAllTopics, deleteCmpTopic, createCmpTopic, frontEndBaseURL } from '../../../API/Api'
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { jwtDecode } from "jwt-decode";


const _ComplianceTopicMonitoringTable = () => {
  const currentLocation = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false); 
  const [cmpTopicsData, setCmpTopicsData] = useState([]) 
  
  // sorting function not working
  const token = localStorage.getItem('accessToken')
  const decodedToken =  jwtDecode(token)

  useEffect( () => {
    const fetchData = async () => { 
      const topicsData = await getCmpAllTopics(token)
      setCmpTopicsData(topicsData.data) 
      setLoading(false); 
    }
    fetchData()
      }, // console.error('Error fetching KYC data:', error);
  [loading]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
    setDisplayedUsers( users.filter((user) => user.name.toLowerCase().includes(e.target.value.toLowerCase())) )
  };

  const handleDeleteTopic = async (topicId) => {
    try {
      await deleteCmpTopic(topicId, token) 
      setLoading(true)
      toast.success("Topic deleted!")
    } catch(e) {
      toast.error("Something went wrong...")
      console.log(e)
    }
  }

  const handleDuplicateTopic = async (clonedTopic) => {
    delete clonedTopic._id
    try {
      await createCmpTopic(clonedTopic, token)
      toast.success("Topic Cloned!")
      setLoading(true)
    } catch(e) {
      toast.error("Something went wrong...")
      console.log(e)
    }
  }

  return (
    <div >
      <Row> 
        <div className="w-full flex justify-between items-center p-4 bg-white shadow">
          <strong> Compliance Monitoring Programme Topics</strong>
          <input type="text" placeholder="Search" value={searchTerm} onChange={handleSearchChange} className="p-2 border border-gray-300 rounded-lg mr-2 w-1/3" /> 

          <Link to='/compliance-monitoring/topic-form' replace={currentLocation.pathname === '/compliance-monitoring/topic-form'}>
            <button className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg">
              Create New Topic
            </button>
          </Link>

          <button onClick={() => setCollapsed(!collapsed)} className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-2 rounded-full">
            <BsChevronDown/>
          </button>
        </div>
      </Row> 
      {!collapsed && (
          <div>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <div>
            <table className="w-full border-collapse border border-gray-300 shadow table-auto">
              <thead>
                <tr className="bg-gray-300 text-left">
                  <th className="p-2 bg-green-200">Topic</th>
                  <th className="p-2 bg-blue-200">No of Questions</th>
                  <th className="p-2">Last updated</th>
                  <th className="p-2 text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {cmpTopicsData.map((topic, index) => (
                  <tr key={topic.id} className={index % 2 === 0 ? 'bg-green-50 hover:bg-green-100' : 'bg-white hover:bg-green-100'}>
                    <td className="p-2 w-3/12">{topic.topicName}</td>
                    <td className="p-2 w-3/12">{topic?.totalQuestions}</td>
                    {/* <td className="p-2 w-3/12">{topic?.subTopics.reduce((total, subtopic) => total + (subtopic.questions?.length || 0), 0)}</td> */}
                    <td className="p-2 w-3/12"> {topic.createdAt } </td>
                    <td className="p-2 w-1/12">
                      <div className="flex">
                        <Tooltip title={"Delete"} className='border border-stone-500 px-1.5 pt-1.5 mx-1 bg-red-400 cursor-pointer hover:bg-red-500 transition duration-500 rounded-full' arrow>  
                          <div>
                            <Modal title="Delete Compliance Topic" CustomButton={()=> <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5.73708 6.54391V18.9857C5.73708 19.7449 6.35257 20.3604 7.11182 20.3604H16.8893C17.6485 20.3604 18.264 19.7449 18.264 18.9857V6.54391M2.90906 6.54391H21.0909" stroke="#1C1C1C" stroke-width="1.7" stroke-linecap="round"></path> <path d="M8 6V4.41421C8 3.63317 8.63317 3 9.41421 3H14.5858C15.3668 3 16 3.63317 16 4.41421V6" stroke="#1C1C1C" stroke-width="1.7" stroke-linecap="round"></path> </g></svg>}>
                              <DeleteCmpTopicModal handleDeleteTopic={handleDeleteTopic} topic={topic}/>
                            </Modal>
                          </div> 
                        </Tooltip>  

                        <Tooltip title={"Edit Topic"} className='cursor-pointer' arrow>
                          <Link to={`/compliance-monitoring/topic-form?topicId=${topic._id}`} replace={currentLocation.pathname === `/compliance-monitoring/topic-form?topicId=${topic._id}`} className='border-stone-500 text-center bg-green-200 hover:bg-green-400 border transition duration-500 rounded-full p-1.5 '>
                              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clip-rule="evenodd" d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z" fill="#000000"/> </svg> 
                          </Link>
                        </Tooltip>

                        <Tooltip title={"Duplicate Topic"} onClick={() => handleDuplicateTopic(topic)} className='p-2.5 mx-1 rounded-full border border-stone-500 cursor-pointer hover:bg-gray-400  transition duration-500' arrow>  <BsCopy/>  </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          )}
        </div>
      )}
    </div>
  );
};


export default _ComplianceTopicMonitoringTable


const DeleteCmpTopicModal = ({handleCloseModal, handleDeleteTopic, topic}) => {
  return (
  <div className='px-9 py-6'> 
    <p className='mb-5'> Are you sure you want to delete this Compliance Topic?  </p>
    <div className='flex justify-between'>
      <button onClick={() => handleDeleteTopic(topic._id)} className='px-6 py-2 mr-4 border border-red-700 bg-red-500 hover:bg-red-600 text-white'> 
        Delete 
      </button>
      <button onClick={() => handleCloseModal()} className='px-6 py-2 mx-6 border bg-slate-300'> Cancel </button>
    </div>
  </div>
  )
}
