         // fullName, Gender, residentialAddress, uniqueIdNumber, DocumentExpiryDate, 
        // dateOfBirth, residentialStatus, Email, contactNumber, Nationality,
        // proofOfAddressDoc, 
        // currentOrFormerPEP,
        // familyPEP, relationshipPEP, pepFamilyName, Country/Organisation, pepFamilyPublicFunctionNature, pepFamilyRoleFrom, pepFamilyRoleTo
        // closeAssociatePEP, 
import React, {useState} from 'react'; 
import { Row } from '../../shared/utils'; 
import { uploadFile } from "../../../API/Api"
import CountryList from './CountryList'          
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip'; 
import { jwtDecode } from "jwt-decode";

const ConnectedOrBeneficialParty = ({formData, token, disabled, setFormData, personType, formName, isValidEmail, setIsValidEmail, borderColor} ) => {
  const [currentPage, setCurrentPage] = useState(0);
  const decodedToken =  jwtDecode(token)
  const companyId = decodedToken.companyId

  const handlePartyInputChange  = (value, index, field, isCheckbox = false) => {
    const updatedParties = [...formData[formName]]
    updatedParties[index][field] = value

    console.log(value, index, field)
    if (isCheckbox) {
      updatedParties[index][field] = !updatedParties[index][field] 
    }

    const newData = {
      ...formData,
      [formName]: updatedParties
    } 

    setFormData(newData)  
  }
  
  const handlePartyFileUpload = async (e, index, field) => {
    const file = e.target.files[0];
    if (!file) { return }

    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${field}_${file.name}`;
      const res = await uploadFile(data, filename,token); 
      const updatedParties = [...formData[formName]]
      updatedParties[index][field] = res.data.url;
      setFormData({
        ...formData,
        [formName]: updatedParties
      })

    } catch (e) {
      console.log("err", e)
      toast.error("error uploading file...")
    }
  }

  const handleFileRemove = async (fileName, index) => {
    if (disabled) {return}


    const updatedFormData = { ...formData };
    updatedFormData.connectedParty[index][fileName] = '';

    setFormData(updatedFormData)
  }



  const addConnectedParty = () => { 
    // formData[formName] //When you use bracket notation (`formData[formName].formName is trying to access a property named formName directly on the formData object, rather than using the value of the formName variable to access a property.
    // vs formData[formName].formName
    console.log(formData)

    if (formData[formName].length > 14) {
      console.log(formData)
      toast.error(`Max 15 ${personType} allowed!`)
      return
    }


    setFormData({ ...formData,
      [formName]: [...formData[formName],
        { authorisedSignatory: '', ultimateBeneficialOwner: '', connectedParty: '',
          name: '', gender: '', address: '', addressCountry: 'SG', addressProofURL: '',
          identificationNumber: '', identificationDocumentURL: '', identificationExpiryDate: '', 
          dateOfBirth: '', email: '', residenceStatus: '', nationality: 'SG', contactNumber : '', 
          pepPerson: 'No', pepPersonCountryOrOrganisation: '', pepPersonPublicFunctionNature: '', pepPersonRoleFrom: '', pepPersonRoleTo: '', 
          pepAssociate: 'No', pepAssociateRelationship: '',  pepAssociateName: '', pepAssociateCountryOrOrganisation: '', pepAssociatePublicFunctionNature: '',pepAssociateRoleFrom: '', pepAssociateRoleTo: '',
          pepFamily: 'No', pepFamilyRelationship: '', pepFamilyName: '', pepFamilyCountryOrOrganisation: '', pepFamilyPublicFunctionNature: '', pepFamilyRoleFrom: '', pepFamilyRoleTo: '',
      }]
    })
    setCurrentPage(formData[formName].length)
  }

  const deleteConnectedParty = (index) => {
    // const deletedPartyList = [...formData[formName]].splice(index, 1); //This gives you back the pop-ed element instead by assigning. like assign pop, vs just conudct pop operation
    const deletedPartyList = [...formData[formName]];
    deletedPartyList.splice(index, 1) 

    setFormData({
      ...formData,
      [formName]: deletedPartyList
    })
    console.log(formData[formName])
    if (currentPage < 2) {
      setCurrentPage(0); // Reset to the first page if all connected parties are deleted
    } else {
      setCurrentPage(currentPage-1)
    }
  }
  const handleChangePage = (index) => {
    setCurrentPage(index);
  }

  const partyList = formData[formName]   

  return (
    <div className='p-3'>
      <button disabled={disabled}  onClick={addConnectedParty} className={`border border-${borderColor}-500 hover:bg-${borderColor}-400 hover:text-white transition duration-200 text-${borderColor}-500 font-medium py-2 px-4 rounded-lg mb-1`}>
      + Add  {personType} </button>

      {partyList?.map((party, index) => (
        <div key={index} style={{ display: index === currentPage ? 'block' : 'none' }}>
          <Row>
            <p className="text-xl font-semibold underline"> {personType} {index+1} </p>
            {partyList.map((_, index) => (
              <button key={index} onClick={() => handleChangePage(index)} className={`border rounded-lg px-2 hover:bg-${borderColor}-400 ${currentPage === index ? `bg-${borderColor}-500` : ''}`}> {index + 1} </button>
            ))}
            <button disabled={disabled}  onClick={() => deleteConnectedParty(index)} className='border border-red-500 hover:bg-red-400 hover:text-white transition duration-200 text-red-500 font-medium py-1.5 px-4 rounded-lg'>
            - Delete  {personType} {currentPage+1} </button>
          </Row>
          {/* <ToastContainer pauseOnHover={false} pauseOnFocusLoss={false}  limit={3} autoClose={2500}/> */}
          
        <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg">
          {/* 0th row */}
          <div className="grid grid-cols-1 gap-4 mb-4">
              {/* <div>
                <p className="font-medium mb-1"> Nature of Relationship </p>
                <input disabled={disabled} type="text" name='relationship' placeholder="Enter Nature of Relationship" value={formData[formName][index]?.relationship} onChange={(e) => handlePartyInputChange(e.target.value, index, 'relationship')} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
              </div> */}
              <div className='block font-medium border'> 
                <label className='mx-4'>
                  <input disabled={disabled} type="checkbox" name="authorisedSignatory" checked={formData[formName][index]?.authorisedSignatory} onChange={(e) => handlePartyInputChange(formData[formName][index]?.authorisedSignatory, index, 'authorisedSignatory', true)} className="w-4 h-4 my-3.5 mx-1"/> 
                  Authorised Signatory 
                  <Tooltip title={<p style={{ fontSize: "14px" }}> Person authorised to act on one's behalf. </p>} arrow>
                    <span className='text-xl' > ⓘ </span>
                  </Tooltip>
                </label> 
                <label className='mr-4'>
                  <input disabled={disabled} type="checkbox" name="connectedParty"  checked={formData[formName][index]?.connectedParty} onChange={(e) => handlePartyInputChange(formData[formName][index]?.connectedParty, index, 'connectedParty', true)} className="w-4 h-4 mx-1"/> 
                  Connected Party  
                  <Tooltip title={<p style={{ fontSize: "14px" }}> Eg. Company Director </p>} arrow>
                    <span className='text-xl' > ⓘ </span>
                  </Tooltip>
                </label> 
                <label className='mr-4'>
                  <input disabled={disabled} type="checkbox" name="ultimateBeneficialOwner" checked={formData[formName][index]?.ultimateBeneficialOwner} onChange={(e) => handlePartyInputChange(formData[formName][index]?.ultimateBeneficialOwner, index, 'ultimateBeneficialOwner', true)} className="w-4 h-4 mx-1"/> 
                  Ultimate Beneficial Owner 
                  <Tooltip title={<p style={{ fontSize: "14px" }}> Owns more than 25% of the company's shares, or controls more than 25% of the voting rights. </p>} arrow>
                    <span className='text-xl' > ⓘ </span>
                  </Tooltip>
                </label>
              </div>
            </div>

          {/* 1st row */}
          <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label className="block font-medium text-gray-700 mb-1"> Full Name </label>
                <input disabled={disabled} type="text" id="fullName" name='name' placeholder="Enter Full Name" value={formData[formName][index]?.name} onChange={(e) => handlePartyInputChange(e.target.value, index, 'name')} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
              </div>
              <div>
                <label className="block font-medium text-gray-700 my-1">Gender</label>
                <div  className="mt-1 flex items-center">
                  {/* // somehow, radiobutton NEEDS that ? to update properly */}
                  <input disabled={disabled} key={"male"+index} type="radio" id="male" name={"gender" + index} value="male" checked={formData[formName][index]?.gender === 'male'} onChange={(e) => handlePartyInputChange(e.target.value, index, 'gender')} className="mr-1 w-4 h-4"/>
                  {/* <input disabled={disabled} key={"male"+index} type="radio" id="male" name="gender" value="male" checked={formData[formName][index]?.gender === 'male'} onChange={(e) => handlePartyInputChange(e.target.value, index, 'gender')} className="mr-1 w-4 h-4"/> */}
                  <label className="mr-3">Male</label>
                  <input disabled={disabled}  key={"female"+index} type="radio" id="female" name={"gender" + index} value="female" checked={formData[formName][index]?.gender === 'female'} onChange={(e) => handlePartyInputChange(e.target.value, index, 'gender')} className="mr-1 w-4 h-4"/>
                  <label >Female</label>
                  {/* TODO custom radio button  */}

                </div>
              </div>
            </div>
            
            {/* 2nd row */}
            <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
                <p className="block font-medium text-gray-700 mb-1"> Residential Address </p>
                <input disabled={disabled} type="text" id="address" name='address' placeholder="Enter Residential Address" value={formData[formName][index].address} onChange={(e) => handlePartyInputChange(e.target.value, index, 'address')} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
              </div>
              <div>
                <span htmlFor="fileInput" className="block font-medium text-gray-700 m-1.5">Upload Proof of Address </span>
                {formData[formName][index].addressProofURL && ( 
                  <div className='flex'>
                    <a href={formData[formName][index].addressProofURL} className='text-blue-600 mr-2'> {formData[formName][index].addressProofURL.split('/').slice(-1)[0]} </a> 
                    <span className='cursor-pointer' onClick={() => handleFileRemove('addressProofURL', index)}> ❌ </span> 
                  </div>
                )}
                
                <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
                  Browse
                  <input disabled={disabled} type="file" id="fileInput" name="addressProofURL"  onChange={(e) => handlePartyFileUpload(e, index, 'addressProofURL')}  className="hidden"/>
                </label> 
              </div>
            </div>


            {/* 2.5nd row */}
            <div className="grid grid-cols-2 gap-4 mb-4">

              <div>
                <label className="block font-medium text-gray-700 mb-1"> Country of Residence</label>
                <CountryList width={'w-full'} disabled={disabled}  value={formData[formName][index].addressCountry} handleInputChange={(e) => handlePartyInputChange(e.target.value, index, 'addressCountry')} name='addressCountry' />
              </div>
              <div>
                <label htmlFor="nationality" className="block font-medium text-gray-700 mb-1">Nationality</label>
                <CountryList width={'w-full'} disabled={disabled} value={formData[formName][index].nationality} handleInputChange={(e) => handlePartyInputChange(e.target.value, index, 'nationality')} name='nationality' />
              </div>
            </div>

            {/* 3rd row */}
            <div className="grid grid-cols-2 gap-4 mb-4">

              <div>
                <label htmlFor="identificationNumber" className="block font-medium text-gray-700 mb-1">Unique Identification Number</label>
                <input disabled={disabled} type="text" id="identificationNumber" name='identificationNumber' placeholder="Enter Unique Identification Number" value={formData[formName][index].identificationNumber} onChange={(e) => handlePartyInputChange(e.target.value, index, 'identificationNumber')} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
              </div>
              <div className=''>
                <span htmlFor="fileInput" className="block font-medium text-gray-700 mb-1.5">Upload Identification Document</span>
                {formData[formName][index].identificationDocumentURL && ( 
                  <div className='flex'>
                    <a href={formData[formName][index].identificationDocumentURL} className='text-blue-600 mr-2'> {formData[formName][index].identificationDocumentURL.split('/').slice(-1)[0]} </a>
                    <span className='cursor-pointer' onClick={() => handleFileRemove('identificationDocumentURL', index)}> ❌ </span> 
                 </div>)}
                
                <label className="cursor-pointer bg-gray-200 px-1 py-0.5 mt-1 rounded">
                  Browse
                  <input disabled={disabled} type="file" id="fileInput" name="identificationDocumentURL" onChange={(e) => handlePartyFileUpload(e, index, 'identificationDocumentURL')}  className="hidden"/>
                </label>
              </div>
            </div> 

            {/* 4th and 5th row */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <label htmlFor="expiryDate" className="block font-medium text-gray-700 mb-1">Expiry Date of Identification Document (If Applicable) </label>
                <input disabled={disabled} type="date" id="expiryDate" value={formData[formName][index].identificationExpiryDate} name='identificationExpiryDate' onChange={(e) => handlePartyInputChange(e.target.value, index, 'identificationExpiryDate')} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
              </div>
              <div>
                <label htmlFor="dob" className="block font-medium text-gray-700 mb-1">Date of Birth</label>
                <input disabled={disabled} type="date" id="dob" value={formData[formName][index].dateOfBirth} onChange={(e) => handlePartyInputChange(e.target.value, index, 'dateOfBirth')} name="dateOfBirth" className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
              </div>
            </div>

            {/* 6th Row */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div> 
                <label className="block font-medium text-gray-700 mb-1">Contact Number</label>
                <input disabled={disabled} type="tel"  placeholder="Enter Contact Number" name="contactNumber"  value={formData[formName][index].contactNumber} onChange={(e) => handlePartyInputChange(e.target.value, index, 'contactNumber')} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
              </div>
              <div>
                <label className="block font-medium text-gray-700 mb-1">Email</label>
                <input disabled={disabled} type="email" id="email" placeholder="Enter Email" value={formData[formName][index].email} onChange={(e) => handlePartyInputChange(e.target.value, index, 'email')} name="email" className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
                {/* {!isValidEmail && <p style={{ color: 'red' }}>Please enter a valid email address</p>} */}
              </div> 
            </div>

            {/* 7th row */}
            <div className="grid grid-cols-2 gap-4 mb-4">
              {/* <div>
                <label htmlFor="residenceStatus" className="block font-medium text-gray-700 mb-1">Residence Status</label>
                <select id="residenceStatus" value={formData[formName].residenceStatus} onChange={(e) => handlePartyInputChange(e.target.value, index, 'residenceStatus')} name='residenceStatus'className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500">
                  <option value=""> - Please Select - </option>
                  <option value="singaporean">Singaporean</option>
                  <option value="permanentResident">Permanent Resident</option>
                  <option value="foreigner">Foreigner</option>
                  <option value="workPermit">Work Permit</option>
                </select>
              </div> */}

            </div>

            {/* 9th row */}
            <div className="grid grid-cols-2 gap-4 mb-4">
                <div className={formData[formName][index].pepPerson === 'yes' ? "border p-2" : "p-2"}>
                  <label className="block font-medium text-gray-700">Are you a current or Former PEP?</label>
                  <div className="mt-1 flex items-center">
                    <input disabled={disabled} type="radio" name={"pepPerson"+index} value="yes" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepPerson')} checked={formData[formName][index]?.pepPerson === 'yes'} className="mr-1 w-4 h-4" />
                    <label className="mr-2">Yes</label>
                    <input disabled={disabled} type="radio" name={"pepPerson"+index} value="no" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepPerson')} checked={formData[formName][index]?.pepPerson === 'no'} className="mr-1 w-4 h-4"/>
                    <label>No</label>
                  </div>

                  {formData[formName][index].pepPerson === 'yes' && (
                      <div className='mt-3 '> 
                          <label className="block font-medium text-gray-700"> Country/Organisation which PEP holds prominent public function</label>
                          <input disabled={disabled} type="text" name="pepPersonCountryOrOrganisation"  value={formData[formName][index]?.pepPersonCountryOrOrganisation} placeholder="Enter Country/Organisation " onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepPersonCountryOrOrganisation')}  className="w-full p-1.5 mb-3 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500"/> <br/>    
                          <label className="block font-medium text-gray-700"> Nature of prominent public function </label>
                          <input disabled={disabled} type="text" name="pepPersonPublicFunctionNature" value={formData[formName][index]?.pepPersonPublicFunctionNature}  placeholder="Enter Nature of prominent public function" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepPersonPublicFunctionNature')}  className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> <br/>

                          <label className="font-medium text-gray-700"> From: </label>
                          <input disabled={disabled} type="date" name="pepPersonRoleFrom" value={formData[formName][index]?.pepPersonRoleFrom} onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepPersonRoleFrom')} id="date" className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none my-1 focus:border-green-500" />
                          <label className="font-medium text-gray-700"> To </label>
                          <input disabled={disabled} type="date" name="pepPersonRoleTo" value={formData[formName][index]?.pepPersonRoleTo} onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepPersonRoleTo')} id="date" className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
                      </div>
                      )}
                </div>
                
              <div className={formData[formName][index].pepAssociate === 'yes' ? "border p-2" : "p-2"}>
                <label className="block font-medium text-gray-700 mb-1">Are you a Close Associate of any current or former PEP?</label>
                <div className="mt-1 flex items-center">
                  <input disabled={disabled} type="radio"  name={"pepAssociate"+index} value="yes" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepAssociate')} checked={formData[formName][index]?.pepAssociate === 'yes'} className="mr-1 w-4 h-4" />
                  <label className="mr-2">Yes</label> <br/>
                  <input disabled={disabled} type="radio"  name={"pepAssociate"+index} value="no" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepAssociate')} checked={formData[formName][index]?.pepAssociate === 'no'} className="mr-1 w-4 h-4"/>
                  <label>No</label>
                </div>

                {formData[formName][index].pepAssociate === 'yes' && (

                      <div className='mt-3 '> 
                          <p className='font-medium'> Relationship with PEP </p>
                          <input disabled={disabled} type="text" name="pepAssociateRelationship" value={formData[formName][index]?.pepAssociateRelationship} placeholder="Enter relationship with PEP " onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepAssociateRelationship')}  className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/>  
                          <p className='font-medium'> PEP Name </p>
                          <input disabled={disabled} type="text" name="pepAssociateName" value={formData[formName][index]?.pepAssociateName} placeholder="Enter PEP Name" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepAssociateName')}  className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> 
                          <p className='font-medium'> Country/Organisation which PEP holds prominent public function </p> 
                          <input disabled={disabled} type="text" name="pepAssociateCountryOrOrganisation" value={formData[formName][index]?.pepAssociateCountryOrOrganisation} placeholder="Enter Country/Organisation " onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepAssociateCountryOrOrganisation')}  className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/>  
                          <p className='font-medium'> Nature of prominent public function </p> 
                          <input disabled={disabled} type="text" name="pepAssociatePublicFunctionNature" value={formData[formName][index]?.pepAssociatePublicFunctionNature} placeholder="Enter Nature of prominent public function" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepAssociatePublicFunctionNature')}  className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/>  

                          <span className='font-medium'> From: </span>
                          <input disabled={disabled} type="date" id="date" name="pepAssociateRoleFrom" value={formData[formName][index]?.pepAssociateRoleFrom} onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepAssociateRoleFrom')} className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
                          <span className='font-medium'> To </span>
                          <input disabled={disabled} type="date" id="date" name="pepAssociateRoleTo" value={formData[formName][index]?.pepAssociateRoleTo} onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepAssociateRoleTo')} className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
                      </div>
                      )}

              </div>
            </div>
            {/* 10th row */}
            <div className="grid grid-cols-2 gap-4 mb-4">
                <div className={formData[formName][index].pepFamily === 'yes' ? "border p-2" : "p-2"}>
                  <label className="block font-medium text-gray-700">Are you a family member of any current or former PEP?</label>
                  <div className="mt-1 flex items-center">
                    <input disabled={disabled} type="radio" name={"pepFamily"+index} value="yes" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepFamily')} checked={formData[formName][index]?.pepFamily === 'yes'} className="mr-1 w-4 h-4" />
                    <label className="mr-2">Yes</label>
                    <input disabled={disabled} type="radio" name={"pepFamily"+index} value="no" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepFamily')} checked={formData[formName][index]?.pepFamily === 'no'} className="mr-1 w-4 h-4"/>
                    <label >No</label> 
                  </div>

                  {formData[formName][index].pepFamily === 'yes' && (
                    <div className='mt-3 '> 
                          <p className='font-medium'> Relationship with PEP  </p> 
                          <input disabled={disabled} type="text" name="pepFamilyRelationship" value={formData[formName][index]?.pepFamilyRelationship}  placeholder="Enter relationship with PEP " onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepFamilyRelationship')}  className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> <br/>  
                          <p className='font-medium'> PEP Name </p> 
                          <input disabled={disabled} type="text" name="pepFamilyName" value={formData[formName][index]?.pepFamilyName}  placeholder="Enter PEP Name" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepFamilyName')}  className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> <br/>  

                          <p className='font-medium'> Country/Organisation which PEP holds prominent public function  </p> 
                          <input disabled={disabled} type="text" name="pepFamilyCountryOrOrganisation" value={formData[formName][index]?.pepFamilyCountryOrOrganisation}  placeholder="Enter Country/Organisation " onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepFamilyCountryOrOrganisation')}  className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> <br/>  
                          <p className='font-medium'>Nature of prominent public function  </p> 
                          <input disabled={disabled} type="text" name="pepFamilyPublicFunctionNature" value={formData[formName][index]?.pepFamilyPublicFunctionNature}  placeholder="Enter Nature of prominent public function" onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepFamilyPublicFunctionNature')}  className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500"/> <br/>

                          <span className='font-medium'> From:  </span> 
                          <input disabled={disabled} type="date" id="date" name="pepFamilyRoleFrom"value={formData[formName][index]?.pepFamilyRoleFrom}   onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepFamilyRoleFrom')} className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
                          <span className='font-medium'> To  </span> 
                          <input disabled={disabled} type="date" id="date" name="pepFamilyRoleTo" value={formData[formName][index]?.pepFamilyRoleTo}  onChange={(e) => handlePartyInputChange(e.target.value, index, 'pepFamilyRoleTo')} className="w-1/8 p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none mb-3 focus:border-green-500" />
                      </div>
                      )}
                </div> 
            </div>
          </div>
        </div>
      ))} 



</div>
  );
};

export default ConnectedOrBeneficialParty;
