import axios from "axios"; 

// export const frontEndBaseURL = "http://localhost:3000"
export const frontEndBaseURL = process.env.REACT_APP_FRONTEND_URL

//  const API = axios.create({ baseURL: "http://localhost:5001" }); 
export const API = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });


// ---------------- login/reset password ------------------------
export const loginUser = (obj) => API.post(`/user/login`, obj);

export const updateUserOtp = (obj) => API.put(`/user/otp`, obj); // have to manually create for the first time
export const verifyOtp = (obj) => API.post(`/user/verify-otp`, obj );
export const updatePassword = (obj) => API.put(`/user/update-password`, obj);
export const updateForgetPassword = (obj) => API.put(`/user/reset-password`, obj);

// export const resetPassword = (obj, token) => API.post(`/user/resetPassword`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
// export const updatedPasswordWithOTP = (obj, token) => API.post(`/user/updatedPasswordWithOTP`, obj, { headers: { 'Authorization': `Bearer ${token}` } });

// ---------------- Admin ------------------------ 
export const getAllUsers = (token) => API.get(`/user/all`, { headers: { 'Authorization': `Bearer ${token}` } });
export const createUser = (obj, token) => API.post(`/user/create-user`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateUser = (obj, token) => API.put(`/user/updateUser`, obj , { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteUser = (id, token) => API.delete(`/user/deleteUser?id=${id}`, { headers: { 'Authorization': `Bearer ${token}` } });
// export const getUserByEmail = (email) => API.get(`/user/email?email=${email}` );

// ---------------- Profile ------------------------ 
export const updateCompanyDetails = (obj, token) => API.put(`/user/update-company`, obj, { headers: { 'Authorization': `Bearer ${token}` } }); // have to manually create for the first time
export const getUserById = (id, token) => API.get(`/user/getUserById?id=${id}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const getCompanyDetails = (type, token) => API.get(`/user/company?type=${type}`, { headers: { 'Authorization': `Bearer ${token}` } });


// ---------------- SuperAdmin ------------------------ 
export const getAllCompany = (token) => API.get(`/superadmin/company`, { headers: { 'Authorization': `Bearer ${token}` } });
export const createCompany = (obj, token) => API.post(`/superadmin/company`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateCompany = (companyId, token) => API.put(`/superadmin/company?companyId=${companyId}`, null, { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteCompany = (companyId, token) => API.delete(`/superadmin/company?companyId=${companyId}`, { headers: { 'Authorization': `Bearer ${token}` } });


// ---------------- kyc-aml module ------------------------ 
export const createkyc = (obj, token) => API.post(`/kyc/create`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
export const updatekyc = (data, token) => API.put(`/kyc/update`, data, { headers: { 'Authorization': `Bearer ${token}` } });
export const updatekycStatus = (data, token) => API.put(`/kyc/updatestatus`, data, { headers: { 'Authorization': `Bearer ${token}` } });
export const deletekyc = (userId, token) => API.delete(`/kyc/delete?userId=${userId}`, { headers: { 'Authorization': `Bearer ${token}` } });

export const getkyc = (userId, token) => API.get(`/kyc/get?userid=${userId}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const getkycAll = (companyId, token) => API.get(`/kyc/getall?companyId=${companyId}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const getkycCustomerPastRisk = (customerId, token) => API.get(`/kyc/getallcustomer?customerId=${customerId}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const getkycPDF = (userId, token) => API.get(`/kyc/pdf?userId=${userId}`, { headers: { 'Authorization': `Bearer ${token}` }, responseType: 'blob' });
export const getkycRiskAssessment = (userId, token) => API.get(`/kyc/risk?userId=${userId}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const createkycRiskAssessment = (obj, token) => API.post(`/kyc/risk`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
export const updatekycRiskAssessment = (obj, token) => API.put(`/kyc/risk`, obj, { headers: { 'Authorization': `Bearer ${token}` } });

export const createKycComment = (obj, token) => API.post(`/kyc/risk/comment`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
export const createKycNestedSanctionComment = (obj, token) => API.post(`/kyc/risk/sanction_comment`, obj, { headers: { 'Authorization': `Bearer ${token}` } });

export const sanctionsHits = (name, data_source, entity_type, token) => API.get(`/sanctions/hits?name=${name}&data_source=${data_source}&entity_type=${entity_type}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const adverseNewsHits = (name, token) => API.get(`/sanctions/news?name=${name}`, { headers: { 'Authorization': `Bearer ${token}` } });

// ---------------- CMP module ------------------------ 
export const getCmpTopic = (topicId, token) => API.get(`/cmp/topic?topicId=${topicId}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const getCmpAllTopics = (token) => API.get(`/cmp/alltopics`, { headers: { 'Authorization': `Bearer ${token}` } });
export const createCmpTopic = (obj, token) => API.post(`/cmp/topic`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateCmpTopic = (obj, topicId, token) => API.put(`/cmp/topic?topicId=${topicId}`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteCmpTopic = (topicId, token) => API.delete(`/cmp/topic?topicId=${topicId}`, { headers: { 'Authorization': `Bearer ${token}` } });

export const getCmpForm = (formId, token) => API.get(`/cmp/form?formId=${formId}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const getCmpAllForms = (token) => API.get(`/cmp/allforms`, { headers: { 'Authorization': `Bearer ${token}` } });
export const createCmpForm = (obj, token) => API.post(`/cmp/form`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateCmpForm = (obj, token) => API.put(`/cmp/form`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteCmpForm = (formId, token) => API.delete(`/cmp/form?formId=${formId}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const archiveCmpForm = (formId, token) => API.delete(`/cmp/archive-form?formId=${formId}`, { headers: { 'Authorization': `Bearer ${token}` } });

export const getCmpFormToCsv = (formId, token) => API.get(`/cmp/form-to-csv?formId=${formId}`, {   headers: { 'Authorization': `Bearer ${token}` },   responseType: 'arraybuffer' });

// ---------------- notifications ------------------------ 
export const getAllNotifications = (module, token) => API.get(`/notification/getAll?moduleType=${module}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const createNotification = (obj, token) => API.post(`/notification/create`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
export const sendEmailNotification = (obj) => API.post(`/notification/email`, obj); // to auth only requests from FE, have to use CORS



// ---------------- shared ------------------------ 
export const generateFormToken = (tokenData, token) => API.post(`/user/formtoken`, tokenData, { headers: { 'Authorization': `Bearer ${token}` } });
export const uploadFile = (obj, filename, token) => API.post(`/upload?filename=${filename}`, obj, { headers: { 'Authorization': `Bearer ${token}` } }); 
// export const sendEmailNotification = (obj, token) => API.post(`/notification/send`, obj, { headers: { 'Authorization': `Bearer ${token}` } });
  // export const sendEmailNotification = (obj) => API.post(`/notification/send`, obj).then(() => {
  // PNotify.success({
  //   title: 'Notification sent',
  //   modules: {
  //     Desktop: {
  //       desktop: true
  //     }
  //   }
  // })
// });




export const generatePDF = (obj) => API.post(`/generatePDF`, obj, { responseType: 'blob' });
export const generateXLSX = (obj) => API.post(`/generateXLSX`, obj, { responseType: 'blob' });
export const downloadXLSXTemplate = () => API.get(`/downloadXLSXTemplate`, { responseType: 'blob' });
export const downloadFPTemplate = () => API.get(`/downloadFPTemplate`, { responseType: 'blob' });
export const downloadKYCTemplate = () => API.get(`/downloadKYCTemplate`, { responseType: 'blob' });
 
export const getUserSpecificBucket = (email) => API.get(`/bucket/userSpecificBucket?email=${email}`);
export const updateUserBucketFull = (obj) => API.post(`/bucket/user/update`, { obj });
export const deleteUserBucketFull = (obj) => API.post(`/bucket/user/delete`, { obj });

export const getAnnouncements = () => API.get(`/announcement`);

export const getUserBuckets = (email) => API.get(`/bucket/byuser?email=${email}`);
export const getBucketsUsers = (id) => API.get(`/bucket/users?id=${id}`);
export const updateAnnouncement = (obj) => API.post(`/announcement/update/`, { obj });
export const updateQuestions = (obj) => API.post(`/bucket/updateUsersAnswere`, obj);

export const createBucket = (obj) => API.post(`/bucket/create`, obj);
export const createComment = (obj) => API.post(`/comments/create`, obj);
export const updateComment = (obj) => API.post(`/comments/update`, obj);
export const getBuckets = (companyId, type) => API.get(`/bucket/all?companyId=${companyId}&type=${type}`);
export const getUsersBuckets = (companyId) => API.get(`/bucket/getUserBuckets?companyId=${companyId}`);
export const getUserBucketsByEmail = (email) => API.get(`/bucket/getUserBucketsByEmail?email=${email}`);
export const addUsersToBucket = (obj) => API.post(`/bucket/user/add`, obj);
export const deleteCMPBucket = (obj) => API.post(`/bucket/cmp/delete`, obj);
export const saveCMPArchive = (obj) => API.post(`/bucket/cmp/save`, obj);
export const getCMPResponses = (internalReference, questionId) => API.get(`/bucket/cmp/responses?internalReference=${internalReference}&questionId=${questionId}`);

export const getUsersByAgency = (name) => API.get(`/user/agencyUsers?agency=${name}`);
export const getBucketById = (id) => API.get(`/bucket/?id=${id}`);
export const userBucketsById = (id) => API.get(`/bucket/user/id?id=${id}`);

export const updateBucket = (id, obj) => API.post(`/bucket/update`, { id, obj });
export const deleteBucket = (id) => API.post(`/bucket/delete`, { id });

export const deleteAnnualDeclaration = (id) => API.post(`/bucket/annualeDeclaration/delete`, { id });

export const deleteComplaince = (id, email) => API.get(`/deleteComplaince?email=${email}&bucketId=${id}`);

export const assigneAgency = (id, obj) => API.post(`/bucket/assigneAgency`, { id, obj });
export const assigneUser = (id, obj) => API.post(`/bucket/assigneUser`, { id, obj });


export const createAnnualDeclaration = (obj) => API.post(`/annualDeclaration/create`, obj);
export const createFaqtaCrs = (obj = {}) => API.post(`/faqtacrs/create`, { ...obj, url: window.location.hostname });
export const updateAnnualDeclaration = (obj) => API.post(`/annualDeclaration/update`, obj);
export const updateUserFaqtaCrs = (obj) => API.post(`/faqtacrs/update`, obj);
export const getAnnualDeclaration = (id) => API.get(`/annualDeclaration/get?id=${id}`);
export const getSpecificFactaCrs = (email) => API.get(`/faqtacrs/get?id=${email}`);
export const getALLAnnualDeclaration = (companyId) => API.get(`/annualDeclaration/all?companyId=${companyId}`);
export const getAllFaqtaCrs = (email) => API.get(`/faqtacrs/all?email=${email}`);
