import React from 'react';
import { Row } from '../../shared/utils';

function QuestionCard({handleInputChange, AnnexA, questionNumber, question, inputName, value }) {

  let borderColorClass = "border";
  


  return (
    <div className="bg-white rounded-sm p-4 mt-4 mx-2 border">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
        {/* Question and Radio Buttons */}
        <div className="col-span-12 flex flex-col md:flex-row md:items-center">
          <p className='md:w-9/12'>
            <strong>Question {questionNumber}: </strong> 
            {question}
          </p>
          
          <div className="flex mt-2 md:mt-0 mx-2">
            <label className="cursor-pointer flex items-center mr-4">
              <input value='Yes' type="radio" className="peer sr-only" name='response' />
              <span className="w-2.5 h-2.5 rounded-full bg-white ring-2 ring-gray-300 ring-offset-2 ring-offset-white transition-all hover:shadow peer-checked:ring-green-500 peer-checked:bg-green-500 mr-2"></span>
              Yes
            </label>
    
            <label className="cursor-pointer flex items-center">
              <input value='No' type="radio" className="peer sr-only" name='response' />
              <span className="w-2.5 h-2.5 rounded-full bg-white ring-2 ring-gray-300 ring-offset-2 ring-offset-white transition-all hover:shadow peer-checked:ring-green-500 peer-checked:bg-green-500 mr-2"></span>
              No
            </label>
          </div>
        </div>
    
        {/* Additional Form Fields */}
        {/* {formData.response == 'Yes' && AnnexA && ( */}
        {AnnexA && (
          <div className="col-span-1 md:col-span-12 grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div className="col-span-1 md:col-span-2">
              <strong> Annex A: </strong>
            </div>
            <div>
              <p className="font-medium text-gray-700 mb-1"> Name of Regulator/Authority: </p>
              <input type="text" name='regulatorName' placeholder="Enter Name of Regulator/Authority" value={'formData.name'} onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
            <div>
              <p className="font-medium text-gray-700 mb-1"> Nature of incident for affirmative response (“Incident”): </p>
              <input type="text" name='incidentNature' placeholder="Enter Nature of incident for affirmative response (“Incident”)" value={'formData.name'} onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
            <div>
              <p className="block font-medium text-gray-700 mb-1"> Details of Incident: </p>
              <input type="text"name='incidentDetails' placeholder="Enter Details of Incident" value={'formData.identificationNumber'} onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
            <div>
              <p className="block font-medium text-gray-700 mb-1"> Date of Incident: </p>
              <input type="date"name='incidentDate'  value={'formData.identificationNumber'} onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>


            <div>
              <p className="font-medium text-gray-700 mb-1">Status of Incident: </p>
              <input type="text" name='incidentStatus' placeholder="Enter Status of Incident" value={'formData.name'} onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
            <div>
              <p className="font-medium text-gray-700 mb-1"> Penalty amount/No. of years of imprisonment: </p>
              <input type="text" name='penaltyAmount' placeholder="Enter Penalty amount/No. of years of imprisonment" value={'formData.name'} onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
            <div>
              <p className="block font-medium text-gray-700 mb-1"> Remedial measures taken if any: </p>
              <input type="text"name='remedialMeasures' placeholder="Enter Remedial measures taken if any" value={'formData.identificationNumber'} onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
            <div>
              <p className="block font-medium text-gray-700 mb-1"> Progress of remedial measures: </p>
              <input type="text" name='remedialProgress' placeholder="Enter Progress of remedial measures" value={'formData.identificationNumber'} onChange={handleInputChange} className="w-full p-1.5 border-b-2 border-b-gray-300 bg-gray-100 focus:outline-none focus:border-green-500" />
            </div>
            <div className="col-span-1 md:col-span-2">
              <p className="font-medium text-gray-700 mb-1">Reasons that you still meet the Authority's fit and proper criteria set out despite the incident:</p>
              <textarea 
                name="reasons" rows="4"
                placeholder="Enter reasons that you still meet the Authority's fit and proper criteria set out despite the incident"
                className="w-full p-1.5 border-2 border-gray-300 bg-gray-100 focus:outline-none focus:border-green-500 rounded-md"
              ></textarea>
            </div>


          </div>
        )}
      </div>
    </div>
  );
}

export default QuestionCard;



//  <input checked={SDD["6.12"]} style={{ accentdivor: 'green', width: 18, height: 18, marginLeft: '10px', marginRight: '4px' }} 
// type="radio" name="govtEntity" onClick={() => {setComplianceResults({ SDD: { ...SDD, ["6.12"]: true } }) }} /> Yes 
//  <input checked={!SDD["6.12"]} style={{ accentdivor: 'green', width: 18, height: 18, marginLeft: '10px', marginRight: '4px' }} 
// type="radio" name="govtEntity" onClick={() => setComplianceResults({ SDD: { ...SDD, ["6.12"]: false } })} /> No 