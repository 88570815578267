import { useState, useEffect } from "react";
import { uploadFile } from "../../API/Api"
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from "luxon";
import { useForm } from 'react-hook-form';

export function RightContainer({ children, className }) {
  return (
    <div className='flex-1 ml-80 px-9 pt-4 max-w-screen-2xl h-screen'> 
    {/* <div className='flex-1 ml-80 px-9 pt-4 overflow-x-auto max-w-screen-2xl'> */}
        {children}
        {/* h-screen was what made it flexible omg lol. */}
    </div>
  );
}

export function Badge({ children, className }) {
  return (
    <div className={`white rounded-full px-2 py-1 text-xs font-semibold ${className}`}>
      {children}
    </div>
  );
}

export function Card({ children, className }) {
  return (
    <div className={`bg-white rounded-sm shadow-md p-4 mb-4 mx-2 ${className}`}>
      {children}
    </div>
  );
}

export function Row({ children, className }) {
  return (
    <div className={`flex flex-row justify-between ${className}`}>
      {children}
    </div>
  );
}

export function SubmitButton({ children, className, onClick,  }) {
  const [submitted, setSubmitted] = useState(false)
  const handleSubmit = () => {
    setSubmitted(true)

    if (onClick && typeof onClick === 'function') {  
      onClick(); //eg, updateKyc(...)
    }
  }

  return (
    <button onClick={handleSubmit} disabled={submitted} className={`bg-green-500 text-white font-semibold py-2 px-4 rounded-md ${submitted ? 'cursor-not-allowed opacity-50' : 'hover:bg-green-600'} ${className}`}>
      {children}
    </button>
  )
}


// ------------------- forms utils ----------------------


//  This includes RHF, 'register' fx 
{/* <UploadFileInput setValue={setValue} register={register} watch={watch} formDisplayName={''} formName={'fileUrl'}/>  */}
export function UploadFileInput({ watch, register, setValue, formDisplayName, formName, readOnly=false, children, className}) {
  const token = localStorage.getItem('accessToken')
  const decodedToken =  jwtDecode(token)
  const watchedValue = watch(formName); // this is actual name of file  

  const handleFileUpload = async (e, fileType) => {
    // const fileInputFieldName = e.target.name;
    const { name } = e.target;
    const file = e.target.files[0];
    if (!file) { return }
    if (!watchedValue) { setValue(formName, ' '); }

    try {
      const data = new FormData();
      data.append("file", file);
      const filename = `${fileType}_${file.name}`; 

      const res = await uploadFile(data, filename, token); 
      setValue(formName, res.data.url);
    } catch (e) {
      toast.error("Error in uploading file")
    }
  }

  const handleFileRemove = async (fileName) => {
    console.log(formName)
    setValue(formName , '')
  }


  return (
    <div className={`${className}`}>
      <p className="text-sm font-medium text-gray-700 mb-2"> {formDisplayName} </p>
      {watchedValue && watchedValue[0] && typeof watchedValue === 'string' && (
        <p className=' mb-2'>
          <a href={watchedValue} className='text-blue-600 mb-2'> {watchedValue?.split('/')?.slice(-1)[0] || ' '} </a> 
          <span className='cursor-pointer' onClick={() => handleFileRemove(formName)}> ❌ </span> 
        </p>
      )}
      <label className={`bg-gray-200 px-3 py-1 mt-5 rounded ${!readOnly && 'cursor-pointer'}`}>
        Browse
        <input {...register(formName, { onChange: (e) => { handleFileUpload(e, formName) } })} disabled={readOnly} type="file" name={formName} className="hidden"/>
      </label>
    </div>
  );
}



export function CommentSection({parentFormId, formName, disabled, pastComments=[], submitFormComment, readOnly=false }) {
  const token = localStorage.getItem('accessToken')
  const decodedToken =  jwtDecode(token)
  // zod check if comment string is blank, deny it otherwise

  const { register, reset, handleSubmit, setValue, watch, formState: {errors, isSubmitting } } = useForm({
    defaultValues: { 
      comment: '',
      commentId: '' || uuidv4(),
      commentorName: decodedToken.name,
      createdAt: '' || DateTime.now().toFormat('dd-MM-yyyy HH:mm'),
      parentFormId :  parentFormId
    }
    //  append to comments: [], must follow above convention
  });

  // this comment Component might be the one causing issues. 

  const onSubmit = async (commentData) => {
    if (commentData.comment.trim() === '') { return }

    console.log(commentData)
    console.log(commentData.comment)
    console.log('commentData') 


  // line 161 re-enable the submit on Click
    submitFormComment(commentData)
    //somehow even when submitformcomment function is empty, the response label gets reset as well
    
    setValue('comment', []) // RHF, reset() works on ALL fields, hence to reset only 1 field, setValue instead 
  }



  return (
    <div className="font-semibold mb-1 w-full"> 
      Comments: 
      {pastComments.map( (comment) => (
      // {allComments.map( (comment) => (
      // {value.comments.map( (comment) => (
        <div className='my-1 p-1.5 border-b'>
          <p className='text-xs underline font-normal flex justify-between'>
              <span> {comment?.commentorName}: </span> {comment?.createdAt}
          </p>
          <p className='w-full text-sm break-words'>
            {comment?.comment}
          </p>
        </div>
      ))} 
      <div className="flex">
        <input {...register('comment')} placeholder="Write comment"  disabled={disabled} className="w-11/12 p-1 font-normal border-b-2 border-b-gray-300 bg-gray-50 focus:outline-none focus:border-green-500"/>
        {/* <input placeholder="Write comment" name={inputName} value={value?.newComment} onChange={(e) => handleInputChange(e, 'newComment')} className="w-11/12 p-1 font-normal border-b-2 border-b-gray-300 bg-gray-50 focus:outline-none focus:border-green-500"/> */}
        {/* <span className='cursor-pointer'> */}
        <span onClick={handleSubmit(onSubmit)} className={`${!disabled && 'cursor-pointer'}`}>
        {/* <span onClick={(e) => handleSubmitComment(e, 'newComment', inputName)} className='cursor-pointer'> */}
          <svg width="32px" height="32px" viewBox="-6.24 -6.24 36.48 36.48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
          <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
          <rect x="-6.24" y="-6.24" width="36.48" height="36.48" rx="18.24" fill="#25e44b" strokeWidth="0"/> </g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.288"/>
          <g id="SVGRepo_iconCarrier"> <path d="M3 7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2Z" stroke="#f7f7f7" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round"/> </g>
          </svg>
        </span>
      </div>
    </div>  
  )
}
 



 

export function MessageIcon() {
  <div className="scale-50"> 
    <svg width="64px" height="64px" viewBox="-6.24 -6.24 36.48 36.48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
    <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
    <rect x="-6.24" y="-6.24" width="36.48" height="36.48" rx="18.24" fill="#25e44b" strokeWidth="0"/> </g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.288"/>
    <g id="SVGRepo_iconCarrier"> <path d="M3 7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2Z" stroke="#f7f7f7" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round"/> </g>
    </svg>
  </div>
}
  